define("instant-labs/helpers/if-not", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ifNot = ifNot;
  function ifNot(params /*, hash*/) {
    return params[0] != null ? false : true;
  }

  exports.default = Ember.Helper.helper(ifNot);
});