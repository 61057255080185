define('instant-labs/adapters/sample', ['exports', 'instant-labs/adapters/application', 'ember-cli-form-data/mixins/form-data-adapter'], function (exports, _application, _formDataAdapter) {
        'use strict';

        Object.defineProperty(exports, "__esModule", {
                value: true
        });
        exports.default = _application.default.extend(_formDataAdapter.default, {
                urlForQueryRecord: function urlForQueryRecord(query, modelName) {

                        var baseUrl = this.buildURL(modelName, query.id);

                        delete query.id;

                        return '' + baseUrl;
                }
        });
});