define("instant-labs/controllers/auth/login", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    auth: Ember.inject.service(),

    session: Ember.inject.service(),

    loading: false,

    actions: {
      login: function login() {
        this.set("loading", true);

        this.get('auth').login();
      }
    }

  });
});