define("instant-labs/routes/admin/clients/index", ["exports", "instant-labs/mixins/tech-only-mixin"], function (exports, _techOnlyMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_techOnlyMixin.default, {
    titleToken: "Clients",

    sortBy: Object.freeze(["id"]),

    model: function model() {
      return this.store.findAll("client");
    }
  });
});