define("instant-labs/components/samples/sample-krona", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),

    metabolicGroups: Ember.inject.service(),

    url: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this.loadKrona();
    },
    loadKrona: function loadKrona() {
      var _this = this;

      if (this.get("sample.results.length")) {
        this.set('url', null);

        this.metabolicGroups.getSavedGroupAbbvrs().then(function (groups) {

          var path = "/samples/" + _this.get("sample.id") + "/krona";

          _this.get("ajax").request(path, {
            data: {
              groups: groups.join(','),
              removeUnclassifiedResults: true
            }
          }).then(function (_ref) {
            var data = _ref.data;

            var url = data.attributes.url;
            _this.set("url", url);
          });
        });
      }
    },


    actions: {
      reload: function reload() {
        this.loadKrona();
      }
    }
  });
});