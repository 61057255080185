define("instant-labs/components/cards/top-taxonomic-groups-card", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Passed as property.
    samples: null,

    group: "",

    type: 0,

    trend: false,

    init: function init() {
      this._super.apply(this, arguments);

      // Set default group value.
      this.set("group", "kingdom");
    }
  });
});