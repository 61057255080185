define("instant-labs/components/graphs/sunburst-graph", ["exports", "instant-labs/templates/components/graphs/sunburst-graph", "d3"], function (exports, _sunburstGraph, _d) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sortBy: Object.freeze(["cell_count:desc"]),

    _results: Ember.computed.sort("sample.results", "sortBy"),

    layout: _sunburstGraph.default,

    tagName: "svg",

    tooltips: true,

    classNames: ["sunburst-graph"],

    width: 400, //default width

    height: Ember.computed("width", function () {
      return this.get("width");
    }),

    attributeBindings: ["width", "height"],

    data: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      this.draw();
    },
    draw: function draw() {
      var tooltips = this.get("tooltips");

      var results = this._formatResultsD3(this.get("_results"));

      var width = this.get("width");
      var height = this.get("height");
      var radius = Math.min(width, height) / 2 - 10;

      var x = _d.scaleLinear().range([0, 2 * Math.PI]);

      var y = _d.scaleSqrt().range([0, radius]);

      var color = _d.scaleOrdinal(_d.schemeCategory20c);

      var partition = _d.partition();

      var arc = _d.arc().startAngle(function (d) {
        return Math.max(0, Math.min(2 * Math.PI, x(d.x0)));
      }).endAngle(function (d) {
        return Math.max(0, Math.min(2 * Math.PI, x(d.x1)));
      }).innerRadius(function (d) {
        return Math.max(0, y(d.y0));
      }).outerRadius(function (d) {
        return Math.max(0, y(d.y1));
      });

      var svg = _d.select(this.element);

      // remove previous paths
      svg.selectAll("g").remove();

      svg = svg.attr("preserveAspectRatio", "xMinYMin meet").attr("viewBox", "0 0 " + width + " " + height).attr("width", width).attr("height", height).append("g").attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

      var root = _d.hierarchy(results);

      root.sum(function (d) {
        return d.count;
      });

      var path = svg.selectAll("path").data(partition(root).descendants());

      path.exit().attr("r", 0).remove();

      var g = path.enter().append("g");

      g.append("path").attr("d", arc).style("fill", function (d) {
        return color(d.data.name);
      }).on("click", click);

      _d.select(this.element).selectAll("text").remove();

      var text = g.append("text").attr("transform", function (d) {
        return "rotate(" + computeTextRotation(d) + ")";
      }).attr("x", function (d) {
        return y(d.y0);
      }).attr("dx", "6") // margin
      .attr("dy", ".35em") // vertical-align
      .text(function (d) {
        if (tooltips) return d.data.name;
      }).style("display", function (d) {
        return d.depth <= 1 ? "block" : "none";
      }); // only show text at depth 0,1

      path.exit().remove();

      function click(d) {
        text.transition().style("display", "none");
        svg.transition().duration(750).tween("scale", function () {
          var xd = _d.interpolate(x.domain(), [d.x0, d.x1]),
              yd = _d.interpolate(y.domain(), [d.y0, 1]),
              yr = _d.interpolate(y.range(), [d.y0 ? 20 : 0, radius]);
          return function (t) {
            x.domain(xd(t));
            y.domain(yd(t)).range(yr(t));
          };
        }).selectAll("path").attrTween("d", function (d) {
          return function () {
            return arc(d);
          };
        }).on("end", function (e) {
          if (e.x0 >= d.x0 && e.x0 < d.x0 + (d.x1 - d.x0)) {
            _d.select(this.parentNode).select("text").attr("transform", function () {
              return "rotate(" + computeTextRotation(e) + ")";
            }).attr("x", function (d) {
              return y(d.y0);
            }).transition().duration(500)
            // only show text at depth 1 more than current
            .style("display", function (e) {
              return d.depth + 1 == e.depth || d.depth == e.depth ? "block" : "none";
            });
          }
        });
      }

      function computeTextRotation(d) {
        return (x(d.x0 + (d.x1 - d.x0) / 2) - Math.PI / 2) / Math.PI * 180;
      }

      _d.select(self.frameElement).style("height", height + "px");
    },
    _formatResultsD3: function _formatResultsD3(results) {
      results = this.get("data").resultsByGroup(results, 1);

      results["UNC"].push({
        name: "Other",
        count: this.get("sample.other_cell_count")
      });

      return {
        children: Object.entries(results).map(function (result) {
          if (result[1].length > 0) return {
            name: result[0],
            children: Object.values(result[1]).map(function (species) {
              return species;
            })
          };else return {};
        })
      };
    }
  });
});