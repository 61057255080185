define("instant-labs/controllers/site-management/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),

    ajax: Ember.inject.service(),

    actions: {
      removeAccess: function removeAccess(site) {
        var path = "/access/remove";

        this.ajax.request(path, {
          method: "POST",
          data: {
            model: "sites",
            model_id: site.id
          }
        }).then(function () {
          // Hide from local store.
          site.unloadRecord();
        });
      }
    }
  });
});