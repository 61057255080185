define("instant-labs/controllers/admin/sample-locations/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    sortBy: Object.freeze(["numericId:asc"]),

    locations: Ember.computed.sort("model", "sortBy"),

    notifications: Ember.inject.service(),

    actions: {
      deleteSampleLocation: function deleteSampleLocation(id) {
        var _this = this;

        var confimDelete = confirm("You are about to delete this sample location. This will also delete all related samples. Please press OK to confirm.");

        if (confimDelete) {
          this.get("store").findRecord("sample-location", id).then(function (location) {
            location.destroyRecord().then(function () {
              _this.get("notifications").success("The sample location was successfuly deleted.", { autoClear: true });
            });
          }).catch(function () {
            _this.get("notifications").error("There was an error deleting the sample location.", { autoClear: true });
          });
        }
      }
    }
  });
});