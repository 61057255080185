define("instant-labs/controllers/admin/samples/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    sortBy: Object.freeze(["numericId:asc"]),

    samples: Ember.computed.sort("model", "sortBy"),

    notifications: Ember.inject.service(),

    actions: {
      deleteSample: function deleteSample(id) {
        var _this = this;

        var confimDelete = confirm("You are about to delete this sample. Please press OK to confirm.");

        if (confimDelete) {
          this.get("store").findRecord("sample", id).then(function (sample) {
            sample.destroyRecord().then(function () {
              _this.get("notifications").success("The sample was successfuly deleted.", { autoClear: true });
            });
          }).catch(function () {
            _this.get("notifications").error("There was an error deleting the sample.", { autoClear: true });
          });
        }
      }
    }
  });
});