define("instant-labs/helpers/long-date", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.longDate = longDate;
  function longDate(date /*, hash*/) {
    return Date.parse(date) ? new Date(date).toLocaleDateString("en-US", { timeZone: "UTC", month: 'long', day: 'numeric', year: 'numeric' }) : null;
  }

  exports.default = Ember.Helper.helper(longDate);
});