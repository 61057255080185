define('instant-labs/components/account/user-menu', ['exports', 'instant-labs/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        session: Ember.inject.service(),

        auth: Ember.inject.service(),

        currentUser: Ember.inject.service(),

        testAnalyzeUrl: _environment.default.TEST_ANALYZE_URL,

        // prevent div wrapping (conflics with bootstrap collapsable nav)
        tagName: '',

        actions: {
            invalidateSession: function invalidateSession() {
                this.auth.logout();
            }
        }

    });
});