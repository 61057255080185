define("instant-labs/routes/admin/samples/index", ["exports", "instant-labs/mixins/tech-only-mixin"], function (exports, _techOnlyMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_techOnlyMixin.default, {
    titleToken: "Samples",

    model: function model() {
      return this.store.query("sample", {
        include: "site,sample_location"
      });
    }
  });
});