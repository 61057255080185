define("instant-labs/models/qpcr-result", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    sample_id: _emberData.default.attr(),
    assay: _emberData.default.attr(),
    cell_count: _emberData.default.attr(),

    // relationships
    sample: _emberData.default.belongsTo("sample"),

    // timestamps
    created: _emberData.default.attr(),
    updated: _emberData.default.attr()
  });
});