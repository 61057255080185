define("instant-labs/components/graphs/sample-metabolic-activities", ["exports", "instant-labs/templates/components/graphs/sample-stacked-bar-chart", "d3"], function (exports, _sampleStackedBarChart, _d2) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    layout: _sampleStackedBarChart.default,

    tagName: "svg",

    classNames: ["sample-metabolic-activies svg-tooltips"],

    width: 600, // default width

    height: Ember.computed("width", function () {
      return this.get("width") * 1 / 3;
    }),

    tooltips: true,

    attributeBindings: ["width", "height"],

    data: Ember.inject.service(),

    type: 0, // 0 = abundance; 1 = quantity

    didReceiveAttrs: function didReceiveAttrs() {
      this.draw();
    },
    draw: function draw() {
      var _this = this;

      var self = this;

      var dataset = {};

      var groups = {};

      this.get("groups").then(function (savedGroups) {
        _this.get("samples").forEach(function (sample) {
          var results = sample.get("results").sortBy("cell_count");

          var date = _this.get("data").formatDate(sample.get("collection_date"));

          dataset[sample.get("sample_location.name") + " - " + date] = _this.get("data").resultsByGroup(results);
        });

        var data = Object.entries(dataset).map(function (d) {
          var o = {};
          o["sample"] = d[0];
          o["total"] = 0;
          Object.entries(d[1]).forEach(function (d) {
            var count = d[1].reduce(function (acc, current) {
              return acc + current.count;
            }, 0);
            if (count > 0) {
              o[d[0]] = count;
              o["total"] += count;
              if (!groups[d[0]]) {
                groups[d[0]] = 0;
              }
            }
          });
          return o;
        });

        groups = Object.entries(groups).filter(function (group) {
          return savedGroups.includes(group[0]);
        }).sort(function (a, b) {
          return b[1] - a[1];
        }).slice(0, 5).map(function (a) {
          return a[0];
        });

        // select svg this component
        var svg = _d2.select(_this.element);

        var margin = { top: 20, right: 10, bottom: 30, left: 30 };

        var width = _this.get("width") - margin.left - margin.right;

        var height = _this.get("height") - margin.top - margin.bottom;

        // remove previous data
        svg.selectAll("g").remove();

        var g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        var x0 = _d2.scaleBand().rangeRound([0, width]).paddingInner(0.1);

        var x1 = _d2.scaleBand().padding(0.05);

        var y = _d2.scaleLinear().rangeRound([height, 0]);

        var color = _d2.scaleOrdinal().range(_d2.schemeCategory20c);

        x0.domain(data.map(function (d) {
          return d.sample;
        }).filter(function (x) {
          return x;
        }));

        var maxPercentage = 0;

        data.forEach(function (sample) {
          Object.entries(sample).forEach(function (_ref) {
            var _ref2 = _slicedToArray(_ref, 2),
                key = _ref2[0],
                value = _ref2[1];

            if (groups.includes(key)) {
              var percentage = self.type == 0 ? value / sample.total : value;

              maxPercentage = percentage > maxPercentage ? percentage : maxPercentage;
            }
          });
        });

        var maxY = self.type == 0 ? (maxPercentage * 100 + 5) % 100 : maxPercentage;

        x1.domain(groups).rangeRound([0, x0.bandwidth()]);

        y.domain([0, maxY]).nice();

        g.append("g").selectAll("g").data(data).enter().append("g").attr("transform", function (d) {
          return "translate(" + x0(d.sample) + ",0)";
        }).selectAll("rect").data(function (d) {
          return groups.map(function (key) {
            return {
              key: key,
              value: d[key],
              total: d["total"]
            };
          });
        }).enter().append("rect").attr("x", function (d) {
          return x1(d.key);
        }).attr("y", function (d) {
          return self.type == 0 ? y(d.value / d.total * 100) // abundance
          : y(d.value); // quanitity
        }).attr("width", x1.bandwidth()).attr("height", function (d) {
          return self.type == 0 ? height - y(d.value / d.total * 100) // abundance
          : height - y(d.value); //quantity
        }).attr("fill", function (d) {
          return color(d.key);
        });

        g.append("g").attr("class", "axis").attr("transform", "translate(0," + height + ")").call(_d2.axisBottom(x0));

        g.append("g").attr("class", "axis").call(_d2.axisLeft(y).ticks(null, "s"));

        var legend = g.append("g").attr("font-family", "sans-serif").attr("font-size", 10).attr("text-anchor", "end").selectAll("g").data(groups.slice()).enter().append("g").attr("transform", function (d, i) {
          return "translate(0," + i * 20 + ")";
        });

        legend.append("rect").attr("x", width - 19).attr("width", 19).attr("height", 19).attr("fill", color);

        legend.append("text").attr("x", width - 24).attr("y", 9.5).attr("dy", "0.32em").text(function (d) {
          return d;
        });
      });
    }
  });
});