define("instant-labs/components/forms/samples/sample-form", ["exports", "instant-labs/components/common/form-component"], function (exports, _formComponent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _formComponent.default.extend({
    notifications: Ember.inject.service(),

    store: Ember.inject.service(),

    sites: Ember.computed(function () {
      return this.get("store").findAll("site");
    }),

    sample_locations: Ember.computed("model.site_id", function () {
      return this.get("model.site_id") ? this.get("store").query("sample-location", {
        site_id: this.get("model.site_id")
      }) : "empty";
    }),

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get("model")) {
        this.set("model", this.get("store").createRecord("sample"));
      }
      Ember.$(".has-tooltip").tooltip();
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get("model.isNew")) {
        this.get("model").unloadRecord();
      }
    },
    save: function save(action) {
      var _this = this;

      var sample = this.get("model");

      var file = document.getElementById("results_file") ? document.getElementById("results_file").files[0] : null;

      var qpcr_file = document.getElementById("qpcr_results_file") ? document.getElementById("qpcr_results_file").files[0] : null;

      sample.set("results_file", file);
      sample.set("qpcr_results_file", qpcr_file);

      sample.save().then(function () {
        var message = action === "create" ? "The sample was successfuly created" : "The sample was successfully updated";

        _this.get("notifications").success(message, {
          autoClear: true
        });

        _this.redirectTo("admin.samples");
      }).catch(function (errors) {
        _this.handleErrors(errors);
      });
    },


    actions: {
      create: function create() {
        this.save("create");
      },
      update: function update() {
        this.save("update");
      },
      updateSiteId: function updateSiteId(id) {
        this.set("model.site_id", id);
        this.set("model.sample_location_id", null);
      },
      defaultDateTimeValue: function defaultDateTimeValue() {
        this.set("model.collection_date", Ember.$("#collection_date").val() + 'T' + (Ember.$("#collection_time").val() === '' ? '00:00' : Ember.$("#collection_time").val()));
      }
    }
  });
});