define("instant-labs/components/samples/samples-overview", ["exports", "instant-labs/utils/hex-to-rgba"], function (exports, _hexToRgba) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    metabolicGroupsService: Ember.inject.service("metabolic-groups"),

    storage: Ember.inject.service(),

    METABOLIC_ACTIVITIES_TEST: "metabolic_activies_test",

    RELATIVE_ABUNDANCE_LIMIT_GROUPS: "relative_abundance_limit_groups",

    metabolicActivitiesTest: Ember.computed("storage", function () {
      return this.storage.getItem(this.METABOLIC_ACTIVITIES_TEST, 0);
    }),

    relativeAbundanceLimitGroups: Ember.computed("storage", function () {
      return this.storage.getItem(this.RELATIVE_ABUNDANCE_LIMIT_GROUPS, 0);
    }),

    metabolicActivitiesType: 0,

    metabolicTrendType: 0,

    generaClassified: Ember.computed("samples", function () {
      return this.get("samples").reduce(function (acc, sample) {
        return acc + sample.get("results").reduce(function (acc, result) {
          return result.get("genus") ? acc + 1 : acc;
        }, 0);
      }, 0);
    }),

    relativeAbundanceSliderVal: 70,

    hasResults: Ember.computed("samples", function () {
      return this.get("samples").filter(function (sample) {
        return sample.get("results.length") > 0 || sample.get("qpcr_results.length") > 0;
      }).length > 0;
    }),

    sampleIds: Ember.computed("samples", function () {
      return this.get("samples").map(function (sample) {
        return sample.get("id");
      });
    }),

    sortedSamples: Ember.computed("samples", function () {
      return this.get("samples").sort(function (a, b) {
        return moment(b.get("collection_date")).diff(moment(a.get("collection_date")));
      });
    }),

    sampleForRelativeAbundancePlot: Ember.computed("sortedSamples", function () {
      var _this = this;

      var initialSample = null;
      var samples = [];
      this.sites.forEach(function (site) {
        if (!initialSample) {
          site.get("sample_locations").forEach(function (location) {
            if (!initialSample) {
              location.get("samples").forEach(function (sample) {
                return _this.sampleIds.includes(sample.get("id")) && samples.push(sample);
              });
              samples.sort(function (a, b) {
                return moment(b.get("collection_date")).diff(moment(a.get("collection_date")));
              }).forEach(function (sample) {
                if (!initialSample) {
                  sample.set("tabSelected", true);
                  initialSample = sample;
                }
              });
            }
          });
        }
      });
      return initialSample;
    }),

    updatedSamples: Ember.computed("sortedSamples", "sampleForRelativeAbundancePlot", function () {
      var _this2 = this;

      return this.get("sortedSamples").map(function (sample) {
        sample.set("tabSelected", _this2.get("sampleForRelativeAbundancePlot") && sample.get("id") === _this2.get("sampleForRelativeAbundancePlot").get("id"));
        return sample;
      });
    }),

    applicableSites: Ember.computed("updatedSamples", function () {
      var _this3 = this;

      var isTabSelected = false;
      var filteredSites = [];
      this.sites.forEach(function (site) {
        var filteredSampleLocations = [];
        site.get("sample_locations").forEach(function (location) {
          var updatedLocation = {};
          var filteredSamples = [];
          location.get("samples").forEach(function (sample) {
            _this3.get("updatedSamples").forEach(function (selectedSample) {
              if (sample.get("id") === selectedSample.get("id")) {
                if (!isTabSelected) {
                  isTabSelected = selectedSample.get("tabSelected");
                }
                filteredSamples.push(selectedSample);
              }
            });
          });
          if (filteredSamples.length > 0) {
            updatedLocation = {
              name: location.get("name"),
              samples: filteredSamples.sort(function (a, b) {
                return moment(b.get("collection_date")).diff(moment(a.get("collection_date")));
              })
            };
            filteredSampleLocations.push(updatedLocation);
          }
        });
        if (filteredSampleLocations.length > 0) {
          var updatedSite = {
            name: site.get("name"),
            sample_locations: filteredSampleLocations
          };
          filteredSites.push(updatedSite);
        }
      });
      return filteredSites;
    }),

    applicableSitesChanged: Ember.observer("applicableSites", function () {
      Ember.run.schedule("afterRender", this, function () {
        this.applyStyles();
      });
    }),

    didInsertElement: function didInsertElement() {
      this.applyStyles();
      this.resetToFirstTab();
    },
    resetToFirstTab: function resetToFirstTab() {
      var firstTab = Ember.$(".collectionDateTab").first();
      if (firstTab && firstTab.closest(".siteContainer")) {
        if (_typeof(firstTab.closest(".siteContainer").data("color")) !== undefined) {
          firstTab.css({
            "background-color": (0, _hexToRgba.default)(firstTab.closest(".siteContainer").data("color"), "0.2")
          });
        }
      }
    },
    applyStyles: function applyStyles() {
      var colorIndex = 0;
      Ember.$(".siteContainer").each(function () {
        Ember.$(this).data("color", _hexToRgba.colorPalette500[colorIndex]);
        Ember.$(this).css({
          "border-color": (0, _hexToRgba.default)(_hexToRgba.colorPalette500[colorIndex++], "0.9")
        });
      });
      Ember.$(".siteName").each(function () {
        Ember.$(this).removeClass("active");
      });
      Ember.$(".collectionDateTab").each(function () {
        Ember.$(this).css({
          "background-color": "#fff"
        });
        Ember.$(this).removeClass("active");
      });
      Ember.$(".collectionDateTab").each(function () {
        Ember.$(this).css({
          "border-color": (0, _hexToRgba.default)(Ember.$(this).closest(".siteContainer").data("color"), "0.9")
        });
        if (typeof Ember.$(this).data("tabSelected") !== "undefined") {
          Ember.$(this).css({
            "background-color": (0, _hexToRgba.default)(Ember.$(this).closest(".siteContainer").data("color"), "0.2")
          });
          Ember.$(this).addClass("active");
        }
      });
      Ember.$(".siteName").each(function () {
        var siteColor = (0, _hexToRgba.default)(Ember.$(this).closest(".siteContainer").data("color"), "0.9");
        Ember.$(this).css({
          "border-color": siteColor,
          "background-color": siteColor
        });
        if (Ember.$(this).siblings(".collectionDateTab.active").length > 0) {
          Ember.$(this).addClass("active");
        }
      });
    },


    actions: {
      setMetabolicActiviesType: function setMetabolicActiviesType(type) {
        if (this.metabolicActivitiesTest == 0) {
          this.set("metabolicActivitiesType", type);
        }
      },
      setMetabolicActivitiesTest: function setMetabolicActivitiesTest(test) {
        this.storage.setItem(this.METABOLIC_ACTIVITIES_TEST, test);
        this.notifyPropertyChange("metabolicActivitiesTest");
      },
      setMetabolicTrendType: function setMetabolicTrendType(type) {
        this.set("metabolicTrendType", type);
      },
      toggleRelativeAbundanceLimitGroups: function toggleRelativeAbundanceLimitGroups() {
        this.storage.setItem(this.RELATIVE_ABUNDANCE_LIMIT_GROUPS, +this.storage.getItem(this.RELATIVE_ABUNDANCE_LIMIT_GROUPS) ? 0 : 1);
        this.notifyPropertyChange("relativeAbundanceLimitGroups");
      },
      showRelativeAbundancePlot: function showRelativeAbundancePlot(sample) {
        this.set("sampleForRelativeAbundancePlot", sample);
      },
      sliderMoved: function sliderMoved(event) {
        this.set("relativeAbundanceSliderVal", event.target.value);
      }
    }
  });
});