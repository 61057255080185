define("instant-labs/components/charts/metabolic-group-trend", ["exports", "d3", "instant-labs/utils/hex-to-rgba", "chart.js"], function (exports, _d2, _hexToRgba, _chart) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    tagName: "canvas",

    classNames: ["chart"],

    data: Ember.inject.service(),

    type: 0, // 0 = abundance; 1 = quantity

    chart: null,

    dateFormat: "MM/DD/YYYY hh:mm A",

    didInsertElement: function didInsertElement() {
      this.renderGraph();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.updateGraph();
    },
    getData: function getData() {
      var _this = this;

      return this.groups.then(function (groups) {
        var self = _this;
        var color = (0, _d2.scaleOrdinal)().range(_d2.schemeCategory10);

        var location = _this.get("location");
        var samples = location.get("samples").sortBy("collection_date").toArray();

        // remove duplicate dates to stop errors rendering
        var sampleDates = [];
        samples = samples.reduce(function (acc, sample) {
          var date = sample.get("collection_date");
          var exists = sampleDates.includes(date);

          if (!exists) {
            acc.push(sample);
          }

          sampleDates.push(date);

          return acc;
        }, []);

        // all dates
        var labels = samples.map(function (sample) {
          return moment(sample.get("collection_date"));
        });

        var groupsAbbrvs = groups.map(function (g) {
          return g.get("abbrv");
        });
        var groupsData = {};
        var sample_count = samples.length;

        // make object with groups as keys and empty data arrays
        groups.forEach(function (group) {
          var description = group.get("description");
          groupsData[description] = new Array(sample_count).fill(0);
        });

        samples.forEach(function (sample, i) {
          var results = _this.data.resultsByGroup(sample.get("results"));
          var sample_total = Number(sample.get("total_cell_count"));

          Object.entries(results).map(function (_ref) {
            var _ref2 = _slicedToArray(_ref, 2),
                abbrv = _ref2[0],
                data = _ref2[1];

            if (groupsAbbrvs.includes(abbrv)) {
              var sum = data.reduce(function (acc, cur) {
                return acc + cur.count;
              }, 0);
              var group = groups.find(function (g) {
                return g.get("abbrv") === abbrv;
              });
              var description = group.get("description");

              groupsData[description][i] = self.type == 0 ? (sum / sample_total * 100).toFixed(1) : sum.toFixed(1);
            }
          });
        });

        var datasets = Object.entries(groupsData).map(function (_ref3) {
          var _ref4 = _slicedToArray(_ref3, 2),
              label = _ref4[0],
              data = _ref4[1];

          var _data = data.map(function (datum, i) {
            if (datum > 0) {
              return {
                x: moment(samples[i].get("collection_date")).format(_this.dateFormat),
                y: datum,
                xLabel: samples[i].get("point")
              };
            }
          }).filter(function (n) {
            return n;
          });
          return {
            label: label,
            data: _data,
            _total: data.reduce(function (a, d) {
              return a + +d;
            }, 0)
          };
        });

        // only show top five groups
        datasets = datasets.sort(function (a, b) {
          return b._total - a._total;
        }).slice(0, 5);

        // add background color after sort
        datasets = datasets.map(function (datum, i) {
          var _color = (0, _hexToRgba.default)(color(i), "0.7");

          datum.backgroundColor = _color;
          datum.borderColor = _color;
          datum.fill = false;

          return datum;
        });

        // return
        return {
          labels: labels,
          datasets: datasets
        };
      });
    },
    updateGraph: function updateGraph() {
      var chart = this.chart;

      if (chart) {
        this.getData().then(function (data) {
          chart.data = data;
          chart.update();
        });
      }
    },
    getChartProps: function getChartProps(labels) {
      var unit = 'day';
      var stepSize = 1;

      var maxDate = moment.max(labels);
      var minDate = moment.min(labels);

      var duration = moment.duration(maxDate.diff(minDate))._data;

      if (duration.years > 0) {
        unit = 'year';
      } else if (duration.months > 0) {
        unit = 'month';
      } else if (duration.days > 0) {
        stepSize = 2;
      } else if (duration.hours > 0) {
        unit = 'hour';
      }

      return {
        unit: unit,
        stepSize: stepSize
      };
    },
    renderGraph: function renderGraph() {
      var _this2 = this;

      var self = this;
      var ctx = this.element;
      var location = this.get("location");

      this.getData().then(function (data) {
        var _getChartProps = _this2.getChartProps(data.labels),
            unit = _getChartProps.unit,
            stepSize = _getChartProps.stepSize;

        var chart = new _chart.default(ctx, {
          type: "line",
          data: data,
          options: {
            animation: false,
            aspectRatio: 4,
            title: {
              display: true,
              text: [location.get("site.name"), location.get("name")],
              position: "right",
              padding: 20,
              lineHeight: 1.5,
              fontSize: 14
            },
            layout: {
              padding: {
                right: -10,
                left: 10,
                top: 0,
                bottom: 0
              }
            },
            scales: {
              xAxes: [{
                type: "time",
                time: {
                  parser: _this2.dateFormat,
                  unit: unit,
                  stepSize: stepSize
                }
              }],
              yAxes: [{
                ticks: {
                  // min: 0,
                  callback: function callback(t) {
                    return self.type == 0 ? t + "%" : t;
                  }
                }
              }]
            },
            tooltips: {
              mode: 'point',
              intersect: true,
              callbacks: {
                title: function title(tooltipItem, data) {
                  return data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index].xLabel + ' - ' + tooltipItem[0].xLabel;
                }
              }
            }
          }
        });

        _this2.set("chart", chart);
      });
    }
  });
});