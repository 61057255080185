define('instant-labs/models/password-reset', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    token: _emberData.default.attr(),
    user_id: _emberData.default.attr(),
    expires_at: _emberData.default.attr(),
    active: _emberData.default.attr(),

    email: _emberData.default.attr(),
    password: _emberData.default.attr(),
    password_confirmation: _emberData.default.attr(),

    user: _emberData.default.belongsTo('user')
  });
});