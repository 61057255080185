define("instant-labs/services/data", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    toArray: function toArray(results) {
      return results.map(function (result) {
        return {
          genus: result.get("genus"),
          family: result.get("family"),
          order: result.get("order"),
          class: result.get("class"),
          phylum: result.get("phylum"),
          kingdom: result.get("kingdom"),
          count: result.get("cell_count"),
          metabolic_groups: result.get("metabolic_groups")
        };
      });
    },
    resultsByGroup: function resultsByGroup(results) {
      var formattedResults = {};

      results.forEach(function (result) {
        Object.values(result.get("metabolic_groups")).forEach(function (group) {
          if (!formattedResults[group]) formattedResults[group] = [];

          formattedResults[group].push({
            name: result.get("genus"),
            count: result.get("cell_count")
          });
        });
      });

      return formattedResults;
    },
    formatDate: function formatDate(date) {
      return Date.parse(date) ? new Date(date).toLocaleDateString("en-US", { timeZone: "UTC" }) : null;
    }
  });
});