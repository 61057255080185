define("instant-labs/models/sample-location", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    numericId: Ember.computed("id", function () {
      return Number(this.get("id"));
    }),
    name: _emberData.default.attr(),
    sample_count: _emberData.default.attr(),
    site_id: _emberData.default.attr(),

    // relationships
    site: _emberData.default.belongsTo("site"),
    samples: _emberData.default.hasMany("sample"),
    samplesWithResultsCount: Ember.computed(function () {
      return this.get('samples').reduce(function (acc, sample) {
        return sample.get('results.length') > 0 ? acc + 1 : acc;
      }, 0);
    })
  });
});