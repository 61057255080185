define("instant-labs/routes/samples", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    sample_store: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      var _transition$queryPara = transition.queryParams,
          sites = _transition$queryPara.sites,
          locations = _transition$queryPara.locations;


      if (sites === undefined || locations === undefined || sites === "" || locations === "") {
        this.transitionTo("index");
      }
    },
    model: function model(params) {
      return this.store.query("site", {
        ids: params.sites,
        include: "sample_locations,samples,results,qpcr_results",
        sample_locations: params.locations
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      this.get("sample_store").clear();

      model.map(function (site) {
        site.get("sample_locations").map(function (location) {
          var samples = location.get("samples").sortBy("collection_date").reverse(); // desc

          samples.map(function (sample, i) {
            if (i < 5) {
              _this.get("sample_store").addSample(sample);
            }
          });
        });
      });
    }
  });
});