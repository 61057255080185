define('instant-labs/routes/auth/reset-password', ['exports', 'instant-labs/mixins/unauth-only-mixin'], function (exports, _unauthOnlyMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthOnlyMixin.default, {
    model: function model(params) {
      if (params.token) {
        return this.store.findRecord('password-reset', params.token);
      }

      return null;
    }
  });
});