define("instant-labs/components/forms/sites/site-form", ["exports", "instant-labs/components/common/form-component"], function (exports, _formComponent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _formComponent.default.extend({
    store: Ember.inject.service(),

    currentUser: Ember.inject.service(),

    users: Ember.computed(function () {
      return this.get("store").query("user", {
        type: "tech"
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get("model")) {
        this.set("model", this.get("store").createRecord("site"));
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get("model.isNew")) {
        this.get("model").unloadRecord();
      }
    },
    save: function save(action) {
      var _this = this;

      var site = this.get("model");

      site.save().then(function () {
        var message = action === "create" ? "The site was successfuly created" : "The site was successfully updated";

        _this.get("notifications").success(message, {
          autoClear: true
        });

        _this.redirectTo("admin.sites");
      }).catch(function (errors) {
        _this.handleErrors(errors);
      });
    },


    actions: {
      create: function create() {
        this.save("create");
      },
      update: function update() {
        this.save("update");
      }
    }
  });
});