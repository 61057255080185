define('instant-labs/services/auth', ['exports', 'instant-labs/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    session: Ember.inject.service(),

    notifications: Ember.inject.service(),

    router: Ember.inject.service(),

    userAgentApplication: null,

    tokenLoading: false,

    forgotPasswordUrl: "/auth/reset-password",

    init: function init() {
      this._super();

      if (this.userAgentApplication === null) {
        this.applicationConfig = {
          clientId: _environment.default.B2C.clientId,
          authority: _environment.default.B2C.authority,
          b2cScopes: _environment.default.B2C.scopes
        };
        this.applicationOptions = {
          redirectUri: _environment.default.B2C.host + '/auth/login',
          validateAuthority: false,
          navigateToLoginRequestUrl: false,
          cacheLocation: 'localStorage',
          postLogoutRedirectUri: _environment.default.B2C.host + '/auth/logout',
          state: 'auth/login' // Msal doesn't reset the random state sometimes ????
        };

        this.userAgentApplication = new Msal.UserAgentApplication(this.applicationConfig.clientId, this.applicationConfig.authority, this.tokenReceivedCallback(this), this.applicationOptions);
      }
    },


    // some javascript magic to get this working correctly
    tokenReceivedCallback: function tokenReceivedCallback(service) {

      // return a function so we can have access to both this
      return function (errorDesc, token, error, tokenType) {
        var graphScopes = [service.applicationConfig.clientId];
        var self = this;

        service.set('tokenLoading', true);

        if (!error) {
          if (tokenType == 'id_token') {

            // get access_token
            self.acquireTokenSilent(graphScopes, null, self.getUser()).then(function (accessToken) {
              service.session.authenticate("authenticator:oauth2", accessToken);
            }, function (error) {
              service.showError(error);
            });
          } else {
            service.showError();
          }
        } else {
          // password reset flow
          if (errorDesc.indexOf('password') !== -1) {
            window.location.href = service.forgotPasswordUrl;
          } else {
            service.showError();
          }
        }
      };
    },
    showError: function showError() {
      var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'There was an error logging you in.';

      this.set('tokenLoading', false);
      this.notifications.error(message);
    },
    login: function login() {
      var application = this.get('userAgentApplication');
      var graphScopes = this.get('graphScopes');

      application.loginRedirect(graphScopes, {
        response_type: "code+id_token"
      });
    },
    getUser: function getUser() {
      return this.userAgentApplication.getUser();
    },
    logout: function logout() {
      return this.userAgentApplication.logout();
    },
    getLogoutUrl: function getLogoutUrl() {
      return 'https://login.microsoftonline.com/fabrikamb2c.onmicrosoft.com/oauth2/v2.0/logout?p=b2c_1_sign_in&post_logout_redirect_uri=https%3A%2F%2Faadb2cplayground.azurewebsites.net%2F';
    }
  });
});