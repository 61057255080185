define("instant-labs/routes/admin/clients/edit", ["exports", "instant-labs/mixins/tech-only-mixin"], function (exports, _techOnlyMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_techOnlyMixin.default, {
    titleToken: function titleToken(model) {
      return "Edit " + model.get("name") || "Edit Client";
    },

    model: function model(params) {
      return this.store.find("client", params.client_id);
    }
  });
});