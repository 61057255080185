define("instant-labs/routes/samples/view", ["exports", "instant-labs/mixins/auth-only-mixin"], function (exports, _authOnlyMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authOnlyMixin.default, {
    titleToken: function titleToken(model) {
      return model.get("collection_date") || "View Sample";
    },

    model: function model(params) {
      var id = params.sample_id;
      return this.store.queryRecord("sample", {
        id: id,
        include: "results"
      });
    }
  });
});