define("instant-labs/components/samples/genera-table", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: '',

    loadingReport: false,

    ajax: Ember.inject.service(),

    notifications: Ember.inject.service(),

    allResults: Ember.computed("sample", "seach", function () {
      var results = this.get("sample.results").sortBy("cell_count").reverse();
      var sum = this.get("sample.total_cell_count");
      return results.map(function (result) {
        result.set("percentage", Math.round(+result.get("cell_count") / sum * 10000) / 100);
        return result;
      });
    }),

    _results: Ember.computed("allResults", function () {
      return this.get("allResults");
    }),

    results: Ember.computed("_results", function () {
      return this.get("_results");
    }),

    actions: {
      searchUpdate: function searchUpdate(search) {
        this.set("_results", this.get("allResults").filter(function (result) {
          return result.get("genus").toLowerCase().includes(search.toLowerCase()) || result.get("metabolic_groups").map(function (g) {
            return g.toLowerCase();
          }).some(function (g) {
            return g.includes(search.toLowerCase());
          });
        }));
      },
      export: function _export() {
        var _this = this;

        this.notifications.success("Exporting results (please disable your pop-up blocker).", {
          autoClear: true
        });
        this.set('loadingReport', true);

        var path = "/samples/" + this.get("sample.id") + "/report";

        this.get("ajax").request(path).then(function (_ref) {
          var data = _ref.data;

          var url = data.attributes.url;
          window.open(url);
          _this.set('loadingReport', false);
        });
      }
    }
  });
});