define("instant-labs/routes/admin/index", ["exports", "instant-labs/mixins/tech-only-mixin"], function (exports, _techOnlyMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_techOnlyMixin.default, {
    titleToken: "Admin Overview",

    ajax: Ember.inject.service(),

    model: function model() {
      return this.get("ajax").request("admin/statistics");
    }
  });
});