define("instant-labs/models/user", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    numericId: Ember.computed("id", function () {
      return Number(this.get("id"));
    }),
    name: _emberData.default.attr(),
    email: _emberData.default.attr(),
    type: _emberData.default.attr(),
    client_id: _emberData.default.attr(),

    // change password specific fields
    current_password: _emberData.default.attr(),
    new_password: _emberData.default.attr(),
    new_password_repeated: _emberData.default.attr(),

    // token specific fields
    token: _emberData.default.attr(),
    token_type: _emberData.default.attr(),
    action: _emberData.default.attr()
  });
});