define("instant-labs/routes/site-management/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: "Owned Sites",

    model: function model() {
      return this.store.findAll("site");
    }
  });
});