define('instant-labs/routes/auth/login', ['exports', 'instant-labs/mixins/unauth-only-mixin'], function (exports, _unauthOnlyMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_unauthOnlyMixin.default, {

        // Page title
        titleToken: 'Login'

    });
});