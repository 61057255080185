define("instant-labs/services/metabolic-groups", ["exports", "instant-labs/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),

    storage: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.presets = [];
      this.groups = [];
      this.savedGroups = [];
      this.fetchedGroups = false;
      this.fetchedPresets = false;
    },
    getGroups: function getGroups() {
      var _this = this;

      if (this.get("fetchedGroups") === true) {
        return Promise.resolve(this.get("groups"));
      } else {
        return this.get("store").findAll("metabolic-group").then(function (result) {
          var groups = result.toArray().sort(function (a, b) {
            return a.get('description').localeCompare(b.get('description'));
          });
          _this.setProperties({
            fetchedGroups: true,
            groups: groups
          });
          _this._checkSavedGroups(groups);
          return groups;
        });
      }
    },
    getPresets: function getPresets() {
      var _this2 = this;

      if (this.get("fetchedPresets") === true) {
        return Promise.resolve(this.get("presets"));
      } else {
        return this.get('store').findAll('metabolic-preset').then(function (result) {
          var presets = result.toArray();
          _this2.setProperties({
            fetchedPresets: true,
            presets: presets
          });
          return presets;
        });
      }
    },
    resetGroupAbbvrs: function resetGroupAbbvrs() {
      var _this3 = this;

      this.getGroups().then(function (groups) {
        var abbrvs = _this3._getAbbrvs(groups);
        _this3.setSavedGroupAbbvrs(abbrvs);
      });
    },
    clearSavedGroupAbbvrs: function clearSavedGroupAbbvrs() {
      this.storage.setItem('metabolic_groups', JSON.stringify([]));

      this._updateActiveGroups();
    },
    setSavedGroupAbbvrs: function setSavedGroupAbbvrs(abbrvs) {
      this.storage.setItem('metabolic_groups', JSON.stringify(abbrvs));

      this._updateActiveGroups();
    },
    getSavedGroupAbbvrs: function getSavedGroupAbbvrs() {
      var local = this.storage.getItem("metabolic_groups", JSON.stringify([]));

      return Promise.resolve(JSON.parse(local));
    },
    getSavedGroups: function getSavedGroups() {
      var _this4 = this;

      return this.getGroups().then(function (groups) {
        return _this4.getSavedGroupAbbvrs().then(function (saved) {
          return groups.filter(function (group) {
            return saved.includes(group.get('abbrv'));
          });
        });
      });
    },
    toggleGroup: function toggleGroup(group) {
      var _this5 = this;

      if (group) {
        group.set("active", !group.get("active"));
      }
      this.getGroups().then(function (groups) {
        var active = groups.filter(function (group) {
          return group.get("active");
        });
        var abbrvs = _this5._getAbbrvs(active);
        _this5.setSavedGroupAbbvrs(abbrvs);
      });
    },
    _getAbbrvs: function _getAbbrvs(groups) {
      return groups.map(function (g) {
        return g.get('abbrv');
      });
    },
    _checkSavedGroups: function _checkSavedGroups(allGroups) {
      var build = this.storage.getItem('build_version', null);

      if (_environment.default.SITE.buildVersion != build) {
        var abbrvs = this._getAbbrvs(allGroups);
        this.setSavedGroupAbbvrs(abbrvs);
        this.storage.setItem('build_version', _environment.default.SITE.buildVersion);
      } else {
        this._updateActiveGroups();
      }
    },
    _updateActiveGroups: function _updateActiveGroups() {
      var _this6 = this;

      this.getSavedGroupAbbvrs().then(function (savedAbbrvs) {
        _this6.getGroups().then(function (groups) {
          var saved = [];

          var newGroups = groups.map(function (group) {
            var active = savedAbbrvs.includes(group.get('abbrv'));

            if (active) {
              saved.push(group);
            }

            group.set('active', active);
            return group;
          });

          _this6.set('groups', newGroups);
          _this6.set('savedGroups', saved);
        });
      });
    }
  });
});