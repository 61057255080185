define("instant-labs/components/charts/top-taxonomic-groups-trend", ["exports", "d3", "instant-labs/utils/hex-to-rgba", "chart.js"], function (exports, _d, _hexToRgba, _chart) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "canvas",

    classNames: ["chart"],

    data: Ember.inject.service(),

    metabolicGroups: Ember.inject.service(),

    type: 0, // 0 = abundance; 1 = quantity

    chart: null,

    dateFormat: "MM/DD/YYYY hh:mm A",

    didInsertElement: function didInsertElement() {
      this.renderGraph();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.updateGraph();
    },
    getData: function getData() {
      var _this = this;

      return this.groups.then(function (savedGroups) {
        var location = _this.get("location");
        var samples = location.get("samples").sortBy("collection_date").toArray();
        var abbrvs = savedGroups.map(function (g) {
          return g.abbrv;
        });
        var color = (0, _d.scaleOrdinal)().range(_d.schemeCategory10);

        // remove duplicate dates to stop errors rendering
        var sampleDates = [];
        samples = samples.reduce(function (acc, sample) {
          var date = sample.get("collection_date");
          var exists = sampleDates.includes(date);

          if (!exists) {
            acc.push(sample);
          }

          sampleDates.push(date);

          return acc;
        }, []);

        var labels = samples.map(function (sample) {
          return moment(sample.get("collection_date"));
        });

        var data = samples.reduce(function (acc, sample, i) {
          sample.get("results").forEach(function (result) {
            // Check if in saved groups.
            var validGroups = result.metabolic_groups.filter(function (value) {
              return abbrvs.includes(value);
            });
            if (validGroups.length) {
              var name = result[_this.group];

              if (!(name in acc)) {
                acc[name] = new Array(samples.length).fill(0);
              }

              acc[name][i] += +result.cell_count;
            }
          }, {});

          return acc;
        }, {});

        // Sort data and get top 10.
        var sortedData = Object.entries(data).sort(function (a, b) {
          var aValue = a[1].reduce(function (a, b) {
            return a + b;
          }, 0);
          var bValue = b[1].reduce(function (a, b) {
            return a + b;
          }, 0);

          return bValue - aValue;
        }).slice(0, 10);

        var datasets = sortedData.map(function (d, di) {
          // Convert data to percentages.
          var data = d[1].map(function (v, i) {
            var sample_total = Number(samples[i].get("total_cell_count"));
            var total_prokaryotes = samples[i].get("totalProkaryotes");
            var percentage = v > 0 ? v / sample_total : 0;

            var value = _this.type === 0 ? (percentage * 100).toFixed(1) : Math.round(percentage * total_prokaryotes);

            if (value > 0) {
              return {
                x: moment(samples[i].get("collection_date")).format(_this.dateFormat),
                y: value,
                xLabel: samples[i].get("point")
              };
            }
          }).filter(function (n) {
            return n;
          });

          var _color = (0, _hexToRgba.default)(color(di), "0.7");

          return {
            label: d[0],
            data: data,
            backgroundColor: _color,
            borderColor: _color,
            fill: false,
            _total: data.reduce(function (a, d) {
              return a + +d;
            }, 0)
          };
        });

        return {
          labels: labels,
          datasets: datasets
        };
      });
    },
    updateGraph: function updateGraph() {
      var chart = this.chart;

      if (chart) {
        this.getData().then(function (data) {
          chart.data = data;
          chart.update();
        });
      }
    },
    getChartProps: function getChartProps(labels) {
      var unit = 'day';
      var stepSize = 1;

      var maxDate = moment.max(labels);
      var minDate = moment.min(labels);

      var duration = moment.duration(maxDate.diff(minDate))._data;

      if (duration.years > 0) {
        unit = 'year';
      } else if (duration.months > 0) {
        unit = 'month';
      } else if (duration.days > 0) {
        stepSize = 2;
      } else if (duration.hours > 0) {
        unit = 'hour';
      }

      return {
        unit: unit,
        stepSize: stepSize
      };
    },
    renderGraph: function renderGraph() {
      var _this2 = this;

      var self = this;
      var ctx = this.element;
      var location = this.get("location");

      this.getData().then(function (data) {
        var _getChartProps = _this2.getChartProps(data.labels),
            unit = _getChartProps.unit,
            stepSize = _getChartProps.stepSize;

        var chart = new _chart.default(ctx, {
          type: "line",
          data: data,
          options: {
            animation: false,
            aspectRatio: 4,
            title: {
              display: true,
              text: [location.get("site.name"), location.get("name")],
              position: "right",
              padding: 20,
              lineHeight: 1.5,
              fontSize: 14
            },
            layout: {
              padding: {
                right: -10,
                left: 10,
                top: 0,
                bottom: 0
              }
            },
            scales: {
              xAxes: [{
                type: "time",
                time: {
                  parser: _this2.dateFormat,
                  unit: unit,
                  stepSize: stepSize
                }
              }],
              yAxes: [{
                ticks: {
                  // min: 0,
                  callback: function callback(t) {
                    return self.type == 0 ? t + "%" : t;
                  }
                }
              }]
            },
            tooltips: {
              mode: 'point',
              intersect: true,
              callbacks: {
                title: function title(tooltipItem, data) {
                  return data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index].xLabel + ' - ' + tooltipItem[0].xLabel;
                }
              }
            }
          }
        });

        _this2.set("chart", chart);
      });
    }
  });
});