define("instant-labs/components/common/metabolism-information", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    metabolicGroups: Ember.inject.service(),

    currentGroup: null,

    configuration: false,

    groups: Ember.computed("metabolicGroups.groups", function () {
      return _emberData.default.PromiseArray.create({
        promise: this.get("metabolicGroups").getGroups()
      });
    }),

    actions: {
      setCurrentGroup: function setCurrentGroup(group) {
        this.removeActiveState();
        this.setActiveState(group.get("id"));
        this.set("currentGroup", group);
      },
      unsetCurrentGroup: function unsetCurrentGroup() {
        this.removeActiveState();
        this.set("currentGroup", null);
      }
    },

    removeActiveState: function removeActiveState() {
      Ember.$(".metabolism-information [data-id]").removeClass("active");
    },
    setActiveState: function setActiveState(id) {
      Ember.$('.metabolism-information [data-id="' + id + '"]').addClass("active");
    }
  });
});