define('instant-labs/services/storage', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    storage: null,

    init: function init() {
      this._super();

      this.storage = localStorage;
    },
    getItem: function getItem(key, defaultValue) {
      var value = this.get('storage').getItem(key);

      if ((value === null || value === '') && defaultValue != null) {
        this.setItem(key, defaultValue);
        return defaultValue;
      }

      return value;
    },
    setItem: function setItem(key, value) {
      return this.get('storage').setItem(key, value);
    },
    removeItem: function removeItem(key) {
      return this.get('storage').removeItem(key);
    },
    clear: function clear() {
      return this.get('storage').clear();
    }
  });
});