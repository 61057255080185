define("instant-labs/components/graphs/threat-meter", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /* Risk Level
       * @reference /public/assets/images/risk-meter.png
       * 
       * 0 - Low
       * 1 - Medium
       * 2 - High
       */

    risk: 0,

    didReceiveAttrs: function didReceiveAttrs() {
      this._computeThreat();
    },


    _computeThreat: Ember.observer("qpcr", function () {
      var total = this.get("qpcr");

      if (total >= Math.pow(10, 6)) this.set("risk", "high");else if (total > Math.pow(10, 5)) this.set("risk", "medium");else this.set("risk", "low");
    })
  });
});