define("instant-labs/components/samples/organisms-by-group", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    groupsService: Ember.inject.service("metabolic-groups"),

    selectedGroup: null,

    groups: null,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      _emberData.default.PromiseArray.create({
        promise: this.get("groupsService").getGroups()
      }).then(function (groups) {
        var sampleGroups = _this.get("sample.results").map(function (sample) {
          return sample.get("metabolic_groups");
        }).reduce(function (prev, curr) {
          return prev.concat(curr);
        });

        // only show applicable groups
        groups = groups.filter(function (group) {
          return sampleGroups.includes(group.get("abbrv"));
        });

        _this.set("groups", groups);
        _this.set("selectedGroup", groups[0]);
      });
    },


    results: Ember.computed("selectedGroup", function () {
      var currentAbbrv = this.get("selectedGroup.abbrv");
      var allResults = this.get("sample.results");
      return allResults.filter(function (result) {
        return result.get("metabolic_groups").includes(currentAbbrv);
      });
    }),

    actions: {
      selectGroup: function selectGroup(group) {
        this.set("selectedGroup", group);
      }
    }
  });
});