define('instant-labs/helpers/metabolic-groups-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.metabolicGroupsList = metabolicGroupsList;
  function metabolicGroupsList(groups) {
    return Object.values(groups).map(function (g) {
      return g.join(', ');
    });
  }

  exports.default = Ember.Helper.helper(metabolicGroupsList);
});