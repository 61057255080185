define("instant-labs/routes/index", ["exports", "instant-labs/mixins/auth-only-mixin"], function (exports, _authOnlyMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authOnlyMixin.default, {
    authenticationRoute: "auth.login",

    titleToken: "Dashboard",

    model: function model() {
      return this.store.query("site", {
        include: "sample_locations"
      });
    }
  });
});