define("instant-labs/services/ajax", ["exports", "ember-ajax/services/ajax", "instant-labs/config/environment"], function (exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    session: Ember.inject.service(),

    // does not support host + namespace
    // as seperate props
    host: _environment.default.APP.API_HOST + "/" + _environment.default.APP.API_NAMESPACE,

    headers: Ember.computed("session.data.authenticated", function () {
      var _get = this.get("session.data.authenticated"),
          access_token = _get.access_token;

      return {
        Authorization: "Bearer " + access_token
      };
    })
  });
});