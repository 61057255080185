define("instant-labs/models/metabolic-group", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    numericId: Ember.computed("id", function () {
      return Number(this.get("id"));
    }),
    abbrv: _emberData.default.attr(),
    description: _emberData.default.attr(),
    business_details: _emberData.default.attr(),
    active: _emberData.default.attr()
  });
});