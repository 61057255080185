define("instant-labs/helpers/if-equal", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ifEqual = ifEqual;
  function ifEqual(params /*, hash*/) {
    return params[0].toString() == params[1].toString() ? params[2] : "";
  }

  exports.default = Ember.Helper.helper(ifEqual);
});