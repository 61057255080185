define("instant-labs/routes/application", ["exports", "instant-labs/config/environment", "ember-simple-auth/mixins/application-route-mixin"], function (exports, _environment, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    routeAfterAuthentication: "index",

    routeBeforeAuthentication: "auth.login",

    currentUser: Ember.inject.service(),

    session: Ember.inject.service(),

    auth: Ember.inject.service(),

    title: function title(tokens) {
      var siteName = _environment.default.SITE.name;
      return tokens.length > 0 ? tokens.join(" - ") + " | " + siteName : siteName;
    },

    init: function init() {
      this._super();

      this.auth.init();
    },
    beforeModel: function beforeModel() {
      return this._loadCurrentUser();
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);
      this._loadCurrentUser();
    },
    _loadCurrentUser: function _loadCurrentUser() {
      var _this = this;

      return this.get("currentUser").load().catch(function (e) {
        var error = e.errors && e.errors.length > 0 ? e.errors[0] : {};
        // check if user is not authorized
        if (String(error.status).charAt(0) === '4') {
          _this.get("auth").logout();
        }
      });
    },


    actions: {
      error: function error(errors) {
        var error = errors.errors && errors.errors[0] ? errors.errors[0] : {
          status: 500,
          detail: "Something went wrong!"
        };

        this.controllerFor("error").setProperties({
          status: error.status,
          detail: error.detail
        });
        return true;
      },
      didTransition: function didTransition() {
        window.scrollTo(0, 0);
      }
    }
  });
});