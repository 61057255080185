define("instant-labs/components/charts/top-taxonomic-groups", ["exports", "d3", "instant-labs/utils/hex-to-rgba", "chart.js"], function (exports, _d, _hexToRgba, _chart) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "canvas",

    classNames: ["chart"],

    data: Ember.inject.service(),

    metabolicGroups: Ember.inject.service(),

    // Array of samples.
    samples: null,

    // What group to display.
    group: "",

    // 0 = realive abundance; 1 = quantity
    type: 0,

    didInsertElement: function didInsertElement() {
      this.renderGraph();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.updateGraph();
    },
    getData: function getData() {
      var _this = this;

      return this.groups.then(function (savedGroups) {
        var samples = _this.get("samples");
        var abbrvs = savedGroups.map(function (g) {
          return g.abbrv;
        });
        var color = (0, _d.scaleOrdinal)().range(_d.schemeCategory10);

        var labels = samples.map(function (sample) {
          return [sample.get("site.name"), sample.get("sample_location.name"), sample.point + ' - ' + _this.data.formatDate(sample.collection_date)];
        });

        var data = samples.reduce(function (acc, sample, i) {
          sample.get("results").forEach(function (result) {
            // Check if in saved groups.
            var validGroups = result.metabolic_groups.filter(function (value) {
              return abbrvs.includes(value);
            });

            if (validGroups.length) {
              var name = result[_this.group];

              if (!(name in acc)) {
                acc[name] = new Array(samples.length).fill(0);
              }

              acc[name][i] += +result.cell_count;
            }
          }, {});

          return acc;
        }, {});

        // Sort data and get top 10.
        var sortedData = Object.entries(data).sort(function (a, b) {
          var aValue = a[1].reduce(function (a, b) {
            return a + b;
          }, 0);
          var bValue = b[1].reduce(function (a, b) {
            return a + b;
          }, 0);

          return bValue - aValue;
        }).slice(0, 10);

        var datasets = sortedData.map(function (d, di) {
          // Convert data to percentages.
          var data = d[1].map(function (v, i) {
            var sample_total = Number(samples[i].get("total_cell_count"));
            var total_prokaryotes = samples[i].get("totalProkaryotes");
            var percentage = v > 0 ? v / sample_total : 0;

            return _this.type === 0 ? (percentage * 100).toFixed(1) : Math.round(percentage * total_prokaryotes);
          });

          return {
            label: d[0],
            data: data,
            backgroundColor: (0, _hexToRgba.default)(color(di), "0.7"),
            _total: data.reduce(function (a, d) {
              return a + +d;
            }, 0)
          };
        });

        return {
          labels: labels,
          datasets: datasets
        };
      });
    },
    _getYAxis: function _getYAxis(data) {
      var _this2 = this;

      if (this.type === 1) {
        var largestY = data.datasets.reduce(function (acc, item) {
          return item._total > acc ? item._total : acc;
        }, 0);

        var prevPowOf10 = 1;

        while (prevPowOf10 * 10 < largestY) {
          prevPowOf10 *= 10;
        }return {
          type: "logarithmic",
          ticks: {
            min: 0,
            max: prevPowOf10 * 10,
            callback: function callback(tickValue) {
              var input = tickValue;

              while (input > 9 && input % 10 == 0) {
                input /= 10;
              }if (input === 1) {
                if (tickValue === 0) {
                  return "0";
                } else {
                  return tickValue.toExponential();
                }
              }

              return null;
            }
          }
        };
      }

      // else
      return {
        type: "linear",
        ticks: {
          min: 0,
          callback: function callback(t) {
            return _this2.type === 0 ? t + "%" : t;
          }
        }
      };
    },
    updateGraph: function updateGraph() {
      var _this3 = this;

      var chart = this.chart;

      if (chart) {
        this.getData().then(function (data) {
          var yAxes = _this3._getYAxis(data);

          chart.options.scales.yAxes = [yAxes];
          chart.data = data;
          chart.update();
        });
      }
    },
    renderGraph: function renderGraph() {
      var _this4 = this;

      var ctx = this.element;

      this.getData().then(function (data) {
        var yAxes = _this4._getYAxis(data);

        var chart = new _chart.default(ctx, {
          type: "bar",
          data: data,
          options: {
            animation: false,
            aspectRatio: 3,
            scales: {
              yAxes: [yAxes]
            }
          }
        });

        _this4.set("chart", chart);
      });
    }
  });
});