define("instant-labs/helpers/config", ["exports", "instant-labs/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.config = config;
  function config(params) {
    var configs = params[0].split(".");

    return configs.reduce(function (acc, current) {
      return acc[current];
    }, _environment.default);
  }

  exports.default = Ember.Helper.helper(config);
});