define('instant-labs/helpers/account-role', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.accountRole = accountRole;
  function accountRole(params) {
    var role = params[0];
    return role.charAt(0).toUpperCase() + role.slice(1);
  }

  exports.default = Ember.Helper.helper(accountRole);
});