define('instant-labs/mixins/auth-only-mixin', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_authenticatedRouteMixin.default, {

        // redirect to login page
        authenticationRoute: 'auth.login'

    });
});