define("instant-labs/controllers/admin/users/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    sortBy: Object.freeze(["numericId:asc"]),

    users: Ember.computed.sort("model", "sortBy"),

    notifications: Ember.inject.service(),

    currentUser: Ember.inject.service(),

    actions: {
      deleteUser: function deleteUser(id) {
        var _this = this;

        var confimDelete = confirm("You are about to delete this user. This will also delete all related sites and samples. Please press OK to confirm.");

        if (confimDelete) {
          this.get("store").findRecord("user", id).then(function (user) {
            user.destroyRecord().then(function () {
              _this.get("notifications").success("The user was successfuly deleted.", { autoClear: true });
            });
          }).catch(function () {
            _this.get("notifications").error("There was an error deleting the user.", { autoClear: true });
          });
        }
      }
    }
  });
});