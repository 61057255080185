define("instant-labs/controllers/samples/view", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    metabolicGroups: Ember.inject.service(),

    savedGroups: Ember.computed("metabolicGroups.savedGroups", function () {
      return _emberData.default.PromiseArray.create({
        promise: this.get("metabolicGroups").getSavedGroupAbbvrs()
      });
    })
  });
});