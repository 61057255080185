define("instant-labs/services/sample-store", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.samples = [];
    },
    setSamples: function setSamples(samples) {
      this.set("samples", samples);
    },
    getSamples: function getSamples() {
      return [].concat(_toConsumableArray(this.get("samples"))).sort(function (a, b) {
        return new Date(a.get("collection_date")) - new Date(b.get("collection_date"));
      });
    },
    removeSample: function removeSample(sample) {
      var id = sample.get("id");
      var samples = this.getSamples().filter(function (sample) {
        return sample.get("id") !== id;
      });
      this.setSamples(samples);
    },
    addSample: function addSample(sample) {
      var samples = this.getSamples();
      samples.push(sample);
      this.setSamples(samples);
    },
    clear: function clear() {
      this.set("samples", []);
    },
    hasSample: function hasSample(sample) {
      return this.getSamples().filter(function (s) {
        return s.get("id") === sample.get("id");
      }).length === 1;
    },
    toggleSample: function toggleSample(sample) {
      if (this.hasSample(sample)) {
        this.removeSample(sample);
      } else {
        this.addSample(sample);
      }
    }
  });
});