define("instant-labs/components/samples/sample-overview", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    data: Ember.inject.service(),

    store: Ember.inject.service(),

    notifications: Ember.inject.service()
  });
});