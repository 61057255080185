define('instant-labs/routes/auth/settings', ['exports', 'instant-labs/mixins/auth-only-mixin'], function (exports, _authOnlyMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authOnlyMixin.default, {

        titleToken: 'Account Settings'

    });
});