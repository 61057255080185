define('instant-labs/components/samples/samples-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    expanded: false,

    actions: {
      toggleExpanded: function toggleExpanded() {
        this.set('expanded', !this.expanded);
      },
      toggleSample: function toggleSample(sample) {
        this.toggleSample(sample);
      }
    }

  });
});