define("instant-labs/components/forms/site-management/site-users", ["exports", "instant-labs/components/common/form-component"], function (exports, _formComponent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _formComponent.default.extend({
    notifications: Ember.inject.service(),

    store: Ember.inject.service(),

    isLoaded: false,

    newUserId: null,

    access: null,

    users: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;

      this.set("newUserId", null);

      Ember.RSVP.all([this.store.query("access", {
        model: "sites",
        model_id: this.get("site.id"),
        access: "view",
        user_type: "user",
        include: "user"
      }), this.store.query("user", {
        type: "user"
      })]).then(function (data) {
        _this.set("isLoaded", true);

        var _data = _slicedToArray(data, 2),
            access = _data[0],
            users = _data[1];

        var accessUserIds = access.map(function (a) {
          return +a.user_id;
        });

        _this.set("access", access);
        _this.set("users", users.filter(function (user) {
          return accessUserIds.indexOf(+user.id) === -1;
        }));
      });
    },


    actions: {
      deleteAccess: function deleteAccess(access) {
        var _this2 = this;

        access.destroyRecord().then(function () {
          _this2.loadData();
        });
      },

      createAccess: function createAccess() {
        var _this3 = this;

        if (this.newUserId) {
          var access = this.store.createRecord("access", {
            model: "sites",
            model_id: this.get("site.id"),
            view: "1",
            update: "0",
            delete: "0",
            user_id: this.newUserId
          });

          access.save().then(function () {
            _this3.loadData();
          });
        }
      }
    }
  });
});