define('instant-labs/helpers/selected', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.selected = selected;
  function selected(params /*, hash*/) {
    return params[0] === params[1] ? 'selected' : '';
  }

  exports.default = Ember.Helper.helper(selected);
});