define("instant-labs/adapters/application", ["exports", "ember-data", "instant-labs/config/environment", "ember-simple-auth/mixins/data-adapter-mixin"], function (exports, _emberData, _environment, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.APP.API_HOST,

    namespace: _environment.default.APP.API_NAMESPACE,

    authorize: function authorize(xhr) {
      var _get = this.get("session.data.authenticated"),
          access_token = _get.access_token;

      xhr.setRequestHeader("Authorization", "Bearer " + access_token);
    },


    // copied from ember data github
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);

      // fix for multipart forms since they do
      // not accept files for PUT requests
      snapshot._attributes["_method"] = "PUT";

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, "updateRecord");

      return this.ajax(url, "POST", { data: data });
    }
  });
});