define("instant-labs/controllers/admin/clients/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notifications: Ember.inject.service(),

    actions: {
      deleteClient: function deleteClient(id) {
        var _this = this;

        var confimDelete = confirm("You are about to delete this client. This will also delete all related projects. Please press OK to confirm.");

        if (confimDelete) {
          this.get("store").findRecord("client", id).then(function (client) {
            client.destroyRecord().then(function () {
              _this.get("notifications").success("The client was successfuly deleted.", { autoClear: true });
            });
          }).catch(function () {
            _this.get("notifications").error("There was an error deleting the client.", { autoClear: true });
          });
        }
      }
    }
  });
});