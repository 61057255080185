define('instant-labs/components/forms/admin/reset-classification-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    ajax: Ember.inject.service(),

    notifications: Ember.inject.service(),

    isLoading: false,

    path: "/admin/reset-classification-details",

    actions: {
      reset: function reset() {
        var _this = this;

        this.set('isLoading', true);

        this.ajax.request(this.path).then(function () {
          _this.notifications.success("Classification details updated.", true);
        }).catch(function () {
          _this.notifications.error("There was an error resetting the classification details.", true);
        }).finally(function () {
          _this.set("isLoading", false);
        });
      }
    }

  });
});