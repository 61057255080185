define("instant-labs/services/current-user", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),

    store: Ember.inject.service(),

    load: function load() {
      var _this = this;

      if (this.get("session.isAuthenticated")) {
        return this.get("store").queryRecord("user", {
          me: true
        }).then(function (user) {
          _this.set("user", user);
        });
      } else {
        return Ember.RSVP.resolve();
      }
    },


    isTech: Ember.computed("user", function () {
      return this.get("user.type") === "tech";
    }),

    isOwner: Ember.computed("user", function () {
      return this.get("user.type") === "owner";
    })
  });
});