define("instant-labs/helpers/date", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.date = date;
  function date(date) {
    return Date.parse(date) ? new Date(date).toLocaleDateString("en-US", { timeZone: "UTC" }) : null;
  }

  exports.default = Ember.Helper.helper(date);
});