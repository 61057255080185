define("instant-labs/components/forms/clients/client-form", ["exports", "instant-labs/components/common/form-component"], function (exports, _formComponent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _formComponent.default.extend({
    notifications: Ember.inject.service(),

    store: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get("model")) {
        this.set("model", this.get("store").createRecord("client"));
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get("model.isNew")) {
        this.get("model").unloadRecord();
      }
    },


    newLogo: Ember.computed("model.logo_img", function () {
      return this.get("model.logo_img") == null;
    }),

    save: function save(action) {
      var _this = this;

      var client = this.get("model");

      if (this.get("newLogo")) client.set("logo_img", document.getElementById("logo_img").files[0]);

      client.save().then(function () {
        var message = action === "create" ? "The client was successfuly created" : "The client was successfully updated";

        _this.get("notifications").success(message, {
          autoClear: true
        });

        _this.redirectTo("admin.clients");
      }).catch(function (errors) {
        _this.handleErrors(errors);
      });
    },


    actions: {
      toggleLogo: function toggleLogo() {
        // toggle logo upload form
        this.set("newLogo", !this.get("newLogo"));
      },
      create: function create() {
        this.save("create");
      },
      update: function update() {
        this.save("update");
      }
    }
  });
});