define("instant-labs/models/access", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    user_id: _emberData.default.attr(),
    model: _emberData.default.attr(),
    model_id: _emberData.default.attr(),
    view: _emberData.default.attr(),
    update: _emberData.default.attr(),
    delete: _emberData.default.attr(),
    user: _emberData.default.belongsTo("user")
  });
});