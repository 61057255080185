define("instant-labs/routes/admin/sample-locations/edit", ["exports", "instant-labs/mixins/tech-only-mixin"], function (exports, _techOnlyMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_techOnlyMixin.default, {
    titleToken: function titleToken(model) {
      return model ? "Edit " + model.get("name") : "Edit Sample Location";
    },

    model: function model(params) {
      var id = params.sample_location_id;
      return this.store.find("sample-location", id);
    }
  });
});