define("instant-labs/helpers/bool-op", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.boolOp = boolOp;
  function boolOp(params /*, hash*/) {
    return params[0] || params[1];
  }

  exports.default = Ember.Helper.helper(boolOp);
});