define("instant-labs/controllers/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),

    sorting: Object.freeze(["id:asc"]),

    projects: Ember.computed.sort("model", "sorting")
  });
});