define("instant-labs/serializers/application", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend({
    // override JSONAPI serializer to only pass attributes
    // to the server, not type or data wrapping
    serialize: function serialize(snapshot) {
      var json = {};
      Object.entries(snapshot._attributes).forEach(function (attr) {
        if (attr[1]) json[attr[0]] = attr[1];
      });
      return json;
    }
  });
});