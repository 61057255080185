define("instant-labs/components/graphs/sample-stacked-bar-chart", ["exports", "instant-labs/templates/components/graphs/sample-stacked-bar-chart", "d3"], function (exports, _sampleStackedBarChart, _d) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _sampleStackedBarChart.default,

    tagName: "svg",

    classNames: ["sample-stacked-bar-chart svg-tooltips"],

    width: 600, // default width

    height: Ember.computed("width", function () {
      return this.get("width") * 1 / 3;
    }),

    tooltips: true,

    attributeBindings: ["width", "height"],

    data: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      this.draw();
    },
    draw: function draw() {
      var _this = this;

      var tooltips = this.get("tooltips");

      if (!tooltips) _d.select(this.element).classed("no-ticks", true);

      var dataset = {};
      this.get("samples").forEach(function (sample) {
        var results = _this.get("data").toArray(sample.get("results").sortBy("cell_count"));

        results.push({
          genus: "Other",
          count: sample.get("other_cell_count"),
          metabolic_groups: sample.get("metabolic_groups")
        });

        var date = _this.get("data").formatDate(sample.get("collection_date"));

        dataset[date] = results.reverse();
      });

      var _data = Object.values(dataset);

      var maxData = _data.map(function (e, i) {
        return i;
      }).sort(function (i, j) {
        return _data[j].length - _data[i].length;
      })[0];

      var maxValue = _data[maxData].length;

      var genera2 = [];

      for (var i = 0; i < maxValue; i++) {
        for (var j = 0; j < _data.length; j++) {
          if (_data[j][i] && !genera2.includes(_data[j][i].genus)) genera2.push(_data[j][i].genus);
        }
      }

      var data = Object.entries(dataset).map(function (d) {
        var o = {};
        o["total"] = Object.values(d[1]).reduce(function (acc, cval) {
          return acc + cval.count;
        }, 0);
        o["sample"] = d[0];
        o["groups"] = {};
        Object.values(d[1]).forEach(function (d) {
          o[d.genus] = d.count / o["total"] * 100;
          o["groups"][d.genus] = d.metabolic_groups;
        });
        return o;
      });

      // select svg this component
      var svg = _d.select(this.element);

      var margin = { top: 20, right: 10, bottom: 30, left: 30 };

      var width = this.get("width") - margin.left - margin.right;

      var height = this.get("height") - margin.top - margin.bottom;

      // remove previous data
      svg.selectAll("g").remove();

      var g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      var x = _d.scaleBand().rangeRound([0, width]).paddingInner(0.05).align(0.1);

      var y = _d.scaleLinear().rangeRound([height, 0]);

      var color = _d.scaleOrdinal(_d.schemeCategory20c);

      x.domain(data.map(function (d) {
        return d.sample;
      }));

      y.domain([0, 100]).nice();

      color.domain(genera2);

      g.append("g").selectAll("g").data(_d.stack().keys(genera2)(data)).enter().append("g").attr("fill", function (d) {
        return color(d.key);
      }).selectAll("rect").data(function (d) {
        return d;
      }).enter().append("rect").attr("x", function (d) {
        return x(d.data.sample);
      }).attr("y", function (d) {
        return y(d[1]);
      }).attr("height", function (d) {
        return y(d[0]) - y(d[1]);
      }).attr("width", x.bandwidth()).on("mouseover", function () {
        if (tooltips) tooltip.style("display", null);
      }).on("mouseout", function () {
        tooltip.style("display", "none");
      }).on("mousemove", function (d) {
        var name = _d.select(this.parentNode).datum().key;
        var groups = Object.entries(d.data.groups).filter(function (d) {
          return d[0] == name;
        })[0][1];
        groups = groups ? groups.join(", ") : "n/a";
        var xPosition = _d.mouse(this)[0] - 255;
        var yPosition = _d.mouse(this)[1] - 60;
        tooltip.attr("transform", "translate(" + xPosition + "," + yPosition + ")");
        tooltip.select(".count").text(Math.round((d[1] - d[0]) * 100) / 100 + "%");
        tooltip.select(".genus").text("Genus: " + name);
        tooltip.select(".groups").text("Metabolic Groups: " + groups);
      });

      g.append("g").attr("class", "axis").attr("transform", "translate(0," + height + ")").call(_d.axisBottom(x));

      g.append("g").attr("class", "axis").call(_d.axisLeft(y).ticks(null, "s")).append("text").attr("x", 2).attr("y", y(y.ticks().pop()) + 0.5).attr("dy", "0.32em").attr("fill", "#000").attr("font-weight", "bold").attr("text-anchor", "start");

      // prepare the tooltips
      var tooltip = svg.append("g").attr("class", "d3-tooltip").style("display", "none");

      tooltip.append("rect").attr("width", 250).attr("height", 60).attr("fill", "white").style("opacity", 0.9);

      tooltip.append("text").attr("class", "count").attr("x", 125).attr("dy", "1.2em").style("text-anchor", "middle").attr("font-size", "12px").attr("font-weight", "bold");

      tooltip.append("text").attr("class", "genus").attr("x", 125).attr("y", 20).attr("dy", "1.2em").style("text-anchor", "middle").attr("font-size", "12px").attr("font-weight", "bold");

      tooltip.append("text").attr("class", "groups").attr("x", 125).attr("y", 40).attr("dy", "1.2em").style("text-anchor", "middle").attr("font-size", "12px").attr("font-weight", "bold");
    }
  });
});