define("instant-labs/components/dashboard/sample-selector", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    router: Ember.inject.service(),

    storage: Ember.inject.service(),

    SELECTED_SITE_IDS: 'selected_site_ids',
    SELECTED_SAMPLE_LOCATION_IDS: 'selected_samples_location_ids',

    submitDisabled: Ember.computed("selectedSampleLocationIds", function () {
      return this.get("selectedSampleLocationIds").length === 0;
    }),

    selectedSiteIds: Ember.computed('storage', function () {
      var ids = this.storage.getItem(this.SELECTED_SITE_IDS, JSON.stringify([]));

      return JSON.parse(ids);
    }),

    selectedSampleLocationIds: Ember.computed('storage', function () {
      var ids = this.storage.getItem(this.SELECTED_SAMPLE_LOCATION_IDS, JSON.stringify([]));

      return JSON.parse(ids);
    }),

    allSites: Ember.computed('sites', function () {
      var _this = this;

      return this.sites.map(function (site) {
        var checked = _this.get('selectedSiteIds').includes(site.get('id'));
        site.set('checked', checked);
        return site;
      });
    }),

    filteredSites: Ember.computed('allSites', {
      get: function get() {
        return this.allSites;
      },
      set: function set(key, value) {
        return value;
      }
    }),

    filterSitesWithSelectedSamples: function filterSitesWithSelectedSamples() {
      var selectedSiteIds = this.get('selectedSiteIds');
      var selectedLocationIds = this.get('selectedSampleLocationIds');
      return this.sites.filter(function (site) {
        if (selectedSiteIds.includes(site.id)) {
          if (site.sample_locations.filter(function (location) {
            return selectedLocationIds.includes(location.id);
          }).length > 0) {
            return true;
          }
        }
        return false;
      }).map(function (site) {
        return site.id;
      });
    },


    actions: {
      searchSites: function searchSites(event) {
        var keyword = event.target.value;
        this.set('filteredSites', keyword.length === 0 ? this.allSites : this.allSites.filter(function (site) {
          return site.data.name.toLowerCase().includes(keyword.toLowerCase());
        }));
      },
      selectSite: function selectSite(site) {

        site.set("checked", !site.get("checked"));

        var id = site.get('id');
        var selectedSites = this.get('selectedSiteIds');
        var selectedSampleLocations = this.get('selectedSampleLocationIds');

        // remove site id
        selectedSites = selectedSites.filter(function (s) {
          return +s !== +id;
        });

        // remove sample location ids
        var locationIds = site.get('sample_locations').map(function (l) {
          return +l.get('id');
        });
        selectedSampleLocations = selectedSampleLocations.filter(function (id) {
          return !locationIds.includes(+id);
        });

        if (site.get("checked")) {
          selectedSites.push(id);
        }

        this.storage.setItem(this.SELECTED_SITE_IDS, JSON.stringify(selectedSites));
        this.storage.setItem(this.SELECTED_SAMPLE_LOCATION_IDS, JSON.stringify(selectedSampleLocations));

        this.notifyPropertyChange("selectedSiteIds");
        this.notifyPropertyChange("selectedSampleLocationIds");
        this.notifyPropertyChange("allSites");
      },
      selectSampleLocation: function selectSampleLocation(id) {

        var selectedLocations = this.get('selectedSampleLocationIds');

        if (selectedLocations.includes(id)) {
          selectedLocations = selectedLocations.filter(function (s) {
            return +s !== +id;
          });
        } else {
          selectedLocations.push(id);
        }

        this.storage.setItem(this.SELECTED_SAMPLE_LOCATION_IDS, JSON.stringify(selectedLocations));

        this.notifyPropertyChange("selectedSampleLocationIds");
      },
      submitForm: function submitForm() {
        this.get("router").transitionTo("samples.overview", {
          queryParams: {
            sites: this.filterSitesWithSelectedSamples().join(","),
            locations: this.get("selectedSampleLocationIds").join(",")
          }
        });
      }
    }
  });
});