define("instant-labs/helpers/in-array", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.inArray = inArray;
  function inArray(params /*, hash*/) {
    return params[0].includes(params[1]);
  }

  exports.default = Ember.Helper.helper(inArray);
});