define("instant-labs/components/common/form-component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    notifications: Ember.inject.service(),

    router: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      Ember.$(".has-tooltip").tooltip();
      Ember.$(this.element).find("input,select").on("change", this.hideError);
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(this.element).find("input,select").off("change");
    },
    hideError: function hideError() {
      Ember.$(this).closest(".form-group").removeClass("has-error");
    },
    handleErrors: function handleErrors(error) {
      var _this = this;

      var errors = error.errors;
      var errorDescription = error.description;
      var form = this.element;
      var fieldErrorLength = errors.reduce(function (sum, error) {
        return error.field ? sum + 1 : sum;
      }, 0);

      // set errors var incase of custom reporting
      this.set("errors", errors);

      // show error notification

      if (fieldErrorLength) {
        this.get("notifications").error("There were one or more errors submitting the form.", { autoClear: true });
      }

      errors.forEach(function (item) {
        if (item.detail) {
          if (item.field) {
            // input field
            var group = Ember.$(form).find("[name='" + item.field + "']").closest(".form-group");

            group.find(".form-error").text(item.detail);
            group.addClass("has-error");
          } else {
            if (errorDescription == "Password reset failed") {
              _this.get("notifications").error("Password reset failed. Please submit a request for support.");
            } else {
              _this.get("notifications").error(item.detail);
            }
          }
        }
      });
    },
    __resetForm: function __resetForm() {
      this.get("notifications").clearAll();
      this.set("errors", null);
      Ember.$(this.element).find(".form-group").each(function () {
        Ember.$(this).removeClass("has-error");
      });
    },
    redirectTo: function redirectTo() {
      var _get;

      (_get = this.get("router")).transitionTo.apply(_get, arguments);
    },


    actions: {
      submitForm: function submitForm() {
        this.get("submitForm")();
      }
    }
  });
});