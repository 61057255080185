define('instant-labs/mixins/unauth-only-mixin', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_unauthenticatedRouteMixin.default, {

        // redirect to index if already authenicated
        routeIfAlreadyAuthenticated: 'index'

    });
});