define("instant-labs/adapters/access", ["exports", "instant-labs/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType(type) {
      return type;
    }
  });
});