define("instant-labs/controllers/admin/projects/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notifications: Ember.inject.service(),

    actions: {
      deleteProject: function deleteProject(id) {
        var _this = this;

        var confimDelete = confirm("You are about to delete this project. This will also delete all related samples and results. Please press OK to confirm.");

        if (confimDelete) {
          this.get("store").findRecord("project", id).then(function (project) {
            project.destroyRecord().then(function () {
              _this.get("notifications").success("The project was successfuly deleted.", { autoClear: true });
            });
          }).catch(function () {
            _this.get("notifications").error("There was an error deleting the project.", { autoClear: true });
          });
        }
      }
    }
  });
});