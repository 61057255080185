define("instant-labs/components/forms/account/activate-account", ["exports", "instant-labs/components/common/form-component"], function (exports, _formComponent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _formComponent.default.extend({
    session: Ember.inject.service(),

    actions: {
      submitForm: function submitForm() {
        var _this = this;

        var user = this.get("user");

        user.set("new_password", this.get("new_password"));
        user.set("new_password_repeated", this.get("new_password_repeated"));
        user.set("action", "activate");

        user.save().then(function () {
          _this.get("session").authenticate("authenticator:oauth2", user.get("email"), user.get("new_password"));
        }).catch(function (errors) {
          _this.handleErrors(errors);
        });
      }
    }
  });
});