define("instant-labs/components/common/notification-container", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    service: Ember.inject.service("notifications"),

    notifications: Ember.computed("service.notifications", function () {
      var notifications = this.get("service");
      return notifications.get("notifications");
    })
  });
});