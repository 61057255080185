define("instant-labs/models/result", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    numericId: Ember.computed("id", function () {
      return Number(this.get("id"));
    }),

    sample_id: _emberData.default.attr(),

    // classifications
    kingdom: _emberData.default.attr(),
    phylum: _emberData.default.attr(),
    class: _emberData.default.attr(),
    order: _emberData.default.attr(),
    family: _emberData.default.attr(),
    genus: _emberData.default.attr(),

    // not currently used
    // species: DS.attr(),

    // cell count per sample sized tested
    cell_count: _emberData.default.attr(),

    // generated by api, not from results table
    metabolic_groups: _emberData.default.attr(),

    // relationships
    sample: _emberData.default.belongsTo("sample"),

    // timestamps
    created: _emberData.default.attr(),
    updated: _emberData.default.attr()
  });
});