define("instant-labs/controllers/projects", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notifications: Ember.inject.service(),

    ajax: Ember.inject.service(),

    actions: {
      generateReport: function generateReport(id) {
        var _this = this;

        var url = "/projects/" + id + "/report";

        this.get("notifications").success("Loading report...", {
          autoClear: true
        });

        this.get("ajax").request(url).then(function (_ref) {
          var data = _ref.data;

          var url = data.attributes.url;
          _this.get("notifications").success("The report has been generated and opened in a new window.", { autoClear: true });
          window.open(url, "_blank");
        }).catch(function () {
          _this.get("notifications").error("There was an error generating the report.");
        });
      }
    }
  });
});