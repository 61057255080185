define("instant-labs/components/forms/change-password", ["exports", "instant-labs/components/common/form-component"], function (exports, _formComponent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _formComponent.default.extend({
    store: Ember.inject.service(),

    currentUser: Ember.inject.service(),

    notifications: Ember.inject.service(),

    actions: {
      submitForm: function submitForm() {
        var _this = this;

        this.setProperties({
          errors: null,
          success: null
        });

        var userId = this.get("currentUser.user.id");

        this.get("store").findRecord("user", userId).then(function (user) {
          user.set("current_password", _this.get("current_password"));
          user.set("new_password", _this.get("new_password"));
          user.set("new_password_repeated", _this.get("new_password_repeated"));
          user.set("action", "change_password");

          user.save().then(function () {
            _this.get("notifications").success("Your password has been updated successfully.", { autoClear: true });
            _this._resetForm();
          }).catch(function (errors) {
            _this.handleErrors(errors);
          });
        });
      }
    },

    _resetForm: function _resetForm() {
      this.setProperties({
        current_password: null,
        new_password: null,
        new_password_repeated: null
      });
    }
  });
});