define('instant-labs/components/samples/action-bar', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    metabolicGroupsService: Ember.inject.service('metabolic-groups'),

    groups: Ember.computed("metabolicGroupsService.groups", function () {
      return _emberData.default.PromiseArray.create({
        promise: this.get("metabolicGroupsService").getGroups()
      });
    }),

    presets: Ember.computed("metabolicGroupsService.presets", function () {
      return _emberData.default.PromiseArray.create({
        promise: this.get("metabolicGroupsService").getPresets()
      });
    }),

    actions: {
      print: function print() {
        window.print();
      },
      toggleGroup: function toggleGroup(group) {
        this.get("metabolicGroupsService").toggleGroup(group);
      },
      clearPreset: function clearPreset() {
        this.get('metabolicGroupsService').resetGroupAbbvrs();
      },
      clearGroups: function clearGroups() {
        this.get('metabolicGroupsService').clearSavedGroupAbbvrs();
      },
      setPreset: function setPreset(preset) {
        var groups = preset.get('groups').split(',');
        this.get('metabolicGroupsService').setSavedGroupAbbvrs(groups);
      }
    }

  });
});