define("instant-labs/components/graphs/genera-pie-chart", ["exports", "instant-labs/templates/components/graphs/sunburst-graph", "d3"], function (exports, _sunburstGraph, _d) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sortBy: Object.freeze(["cell_count"]),

    _results: Ember.computed.sort("sample.results", "sortBy"),

    layout: _sunburstGraph.default,

    tagName: "svg",

    tooltips: true,

    classNames: ["genera-pie-graph svg-tooltips"],

    width: 400, //default width

    height: Ember.computed("width", function () {
      return this.get("width");
    }),

    attributeBindings: ["width", "height"],

    data: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      this.draw();
    },
    draw: function draw() {
      var tooltips = this.get("tooltips");

      var width = this.get("width");

      var height = this.get("height");

      var data = this.get("data").toArray(this.get("_results"));

      data.push({
        genus: "Other",
        count: this.get("sample.other_cell_count")
      });

      data = data.reverse();

      var total = this.get("sample.total_cell_count");

      var radius = Math.min(width, height) / 2;

      var color = _d.scaleOrdinal().range(_d.schemeCategory20c);

      var pie = _d.pie().value(function (d) {
        return d.count;
      }).sort(null)(data);

      var arc = _d.arc().outerRadius(radius - 10).innerRadius(0);

      var svg = _d.select(this.element);

      svg.attr("width", width).attr("height", height);

      svg.selectAll("g").remove();

      var g = svg.append("g").attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

      var path = g.selectAll("arc").data(pie).enter().append("g").attr("class", "arc");

      path.append("path").attr("d", arc).style("fill", function (d) {
        return color(d.data.genus);
      }).on("mouseover", function () {
        if (tooltips) tooltip.style("display", null);
      }).on("mouseout", function () {
        tooltip.style("display", "none");
      }).on("mousemove", function (d) {
        var xPosition = _d.mouse(this)[0] - 255;
        var yPosition = _d.mouse(this)[1] - 70;
        tooltip.attr("transform", "translate(" + xPosition + "," + yPosition + ")");
        tooltip.select(".genus").text("Genus: " + d.data.genus);
        tooltip.select(".count").text(Math.round(d.data.count / total * 10000) / 100 + "%");
        tooltip.select(".groups").text("Metabolic Groups: " + (d.data.metabolic_groups || "N/A"));
      });

      // prepare the tooltips
      var tooltip = g.append("g").attr("class", "d3-tooltip").style("display", "none");

      tooltip.append("rect").attr("width", 250).attr("height", 60).attr("fill", "white").style("opacity", 0.9);

      tooltip.append("text").attr("class", "count").attr("x", 125).attr("y", 0).attr("dy", "1.2em").style("text-anchor", "middle").attr("font-size", "12px").attr("font-weight", "bold");

      tooltip.append("text").attr("class", "genus").attr("x", 125).attr("y", 20).attr("dy", "1.2em").style("text-anchor", "middle").attr("font-size", "12px").attr("font-weight", "bold");

      tooltip.append("text").attr("class", "groups").attr("x", 125).attr("y", 40).attr("dy", "1.2em").style("text-anchor", "middle").attr("font-size", "12px").attr("font-weight", "bold");
    }
  });
});