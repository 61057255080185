define('instant-labs/instance-initializers/auth', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(applicationInstance) {
    var auth = applicationInstance.lookup('service:auth');
    auth.init();
  }

  exports.default = {
    initialize: initialize
  };
});