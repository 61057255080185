define('instant-labs/controllers/auth/settings', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        currentUser: Ember.inject.service()

    });
});