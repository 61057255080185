define("instant-labs/mixins/owner-only-mixin", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (!this.currentUser.isOwner && !this.currentUser.isTech) {
        this.transitionTo("index");
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });
});