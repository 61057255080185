define("instant-labs/helpers/not-eq", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.notEq = notEq;
  function notEq(params /*, hash*/) {
    return params[0] !== params[1];
  }

  exports.default = Ember.Helper.helper(notEq);
});