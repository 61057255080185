define("instant-labs/components/forms/account/edit-profile", ["exports", "instant-labs/components/common/form-component"], function (exports, _formComponent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _formComponent.default.extend({
    store: Ember.inject.service(),

    actions: {
      submitForm: function submitForm() {
        var _this = this;

        var user = this.get("user");

        user.save().then(function () {
          _this.get("notifications").success("Your profile has been updated.", {
            autoClear: true
          });
        }).catch(function (errors) {
          _this.handleErrors(errors);
        });
      }
    }
  });
});