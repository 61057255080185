define("instant-labs/controllers/samples", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ["sites", "locations"],

    sampleStore: Ember.inject.service(),

    metabolicGroupsService: Ember.inject.service("metabolic-groups"),

    actions: {
      toggleSample: function toggleSample(sample) {
        this.get("sampleStore").toggleSample(sample);
      }
    }
  });
});