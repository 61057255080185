define("instant-labs/components/samples/sample-header", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),

    actions: {
      deleteSample: function deleteSample(id) {
        this.get("deleteSample")(id);
      }
    }
  });
});