define("instant-labs/services/notifications", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Service.extend({
    notifications: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set("notifications", []);
    },
    success: function success(message, options) {
      return this._createNotification(message, _extends({}, options, {
        type: "success"
      }));
    },
    error: function error(message, options) {
      return this._createNotification(message, _extends({}, options, {
        type: "danger"
      }));
    },
    clearAll: function clearAll() {
      Ember.$("#notification-container .alert").each(function () {
        Ember.$(this).find(".close").click();
      });
      this.set("notifications", []);
    },
    clear: function clear(id) {
      this.set("notifications", this.get("notifications").filter(function (notification) {
        return notification.id !== id;
      }));
    },
    _createNotification: function _createNotification(message, _options) {
      var currentNotifications = this.get("notifications");

      var options = _extends({
        id: this._uniqueId(),
        type: "success",
        autoClear: false,
        timeout: 5000,
        removed: false
      }, _options);

      this.set("notifications", currentNotifications.concat({
        id: options.id,
        message: message,
        type: options.type,
        removed: options.removed
      }));

      if (options.autoClear) {
        setTimeout(function () {
          Ember.$("#notification-" + options.id).find(".close").click();
        }, options.timeout);
      }

      return options.id;
    },
    _uniqueId: function _uniqueId() {
      return Math.round(Math.random() * 0xffff).toString(16);
    }
  });
});