define("instant-labs/components/samples/genera-tables", ["exports", "instant-labs/utils/hex-to-rgba"], function (exports, _hexToRgba) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    allResults: Ember.computed("sample", function () {
      var results = this.get("sample.results").sortBy("cell_count").reverse();
      var sum = this.get("sample.total_cell_count");
      return results.map(function (result) {
        result.set("percentage", Math.round(+result.get("cell_count") / sum * 10000) / 100);
        return result;
      });
    }),

    results: Ember.computed("allResults", "percentage", function () {
      var maxPercentage = this.get("percentage");
      var totalPercentage = 0;
      return this.get("allResults").filter(function (result) {
        totalPercentage += result.percentage;
        return totalPercentage <= maxPercentage;
      });
    }),

    sampleOrResultsChanged: Ember.observer("sample", "results", function () {
      Ember.run.schedule("afterRender", this, function () {
        this.applyStyles();
      });
    }),

    didInsertElement: function didInsertElement() {
      this.applyStyles();
    },
    applyStyles: function applyStyles() {
      var colorIndex = 0;
      Ember.$(".barLine").each(function () {
        var percent = Ember.$(this).data("percent");
        Ember.$(this).css({
          width: percent,
          "background-color": _hexToRgba.colorPalette500[colorIndex++]
        });
      });
    }
  });
});