define('instant-labs/helpers/has-sample', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({

    sampleStore: Ember.inject.service(''),

    compute: function compute(params) {
      return this.sampleStore.hasSample(params[0]);
    }
  });
});