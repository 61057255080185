define('instant-labs/models/metabolic-preset', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    'name': _emberData.default.attr(),
    'groups': _emberData.default.attr()

  });
});