define("instant-labs/models/site", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    numericId: Ember.computed("id", function () {
      return Number(this.get("id"));
    }),
    name: _emberData.default.attr(),
    user_id: _emberData.default.attr(),
    sample_location_count: _emberData.default.attr(),
    owners: _emberData.default.attr(),

    // relationships
    sample_locations: _emberData.default.hasMany("sample-location"),
    samples: _emberData.default.hasMany("sample"),
    checked: _emberData.default.attr()
  });
});