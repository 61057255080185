define("instant-labs/models/client", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    numericId: Ember.computed("id", function () {
      return Number(this.get("id"));
    }),
    name: _emberData.default.attr(),
    logo_img: _emberData.default.attr("file"),

    // relationships
    users: _emberData.default.hasMany("user")
  });
});