define('instant-labs/components/common/graph-card', ['exports'], function (exports) {
            'use strict';

            Object.defineProperty(exports, "__esModule", {
                        value: true
            });
            exports.default = Ember.Component.extend({

                        actions: {
                                    downloadImage: function downloadImage() {
                                                var _this = this;

                                                var scale = 2;
                                                var svg = Ember.$(this.element).find('svg')[0];

                                                var svgData = new XMLSerializer().serializeToString(svg);

                                                var svgSize = svg.getBBox();

                                                var canvas = document.createElement("canvas");
                                                var ctx = canvas.getContext("2d");

                                                canvas.width = svgSize.width * 1.1 * scale;
                                                canvas.height = svgSize.height * 1.1 * scale;

                                                var img = document.createElement("img");

                                                img.setAttribute("src", "data:image/svg+xml;base64," + btoa(svgData));

                                                img.onload = function () {

                                                            ctx.fillStyle = 'white';
                                                            ctx.fillRect(0, 0, canvas.width, canvas.height);

                                                            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                                                            var link = document.createElement('a');
                                                            link.download = new String(_this.get('header')).trim().replace(/\s+/g, '-').toLowerCase();
                                                            link.href = canvas.toDataURL("image/png");
                                                            link.click();
                                                };
                                    }
                        }

            });
});