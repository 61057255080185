define('instant-labs/authenticators/oauth2', ['exports', 'ember-simple-auth/authenticators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({

    auth: Ember.inject.service(),

    restore: function restore(data) {
      return new Promise(function (resolve, reject) {
        if (!Ember.isEmpty(data.access_token)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },


    authenticate: function authenticate(token) {

      return new Promise(function (resolve) {
        Ember.run(function () {
          return resolve({
            access_token: token
          });
        });
      });
    },

    invalidate: function invalidate(data) {
      return Promise.resolve(data);
    }
  });
});