define("instant-labs/controllers/admin/companies/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notifications: Ember.inject.service(),

    actions: {
      deleteCompany: function deleteCompany(id) {
        var _this = this;

        var confimDelete = confirm("You are about to delete this company. This will also delete all related clients and subsequent projects. Please press OK to confirm.");

        if (confimDelete) {
          this.get("store").findRecord("company", id).then(function (company) {
            company.destroyRecord().then(function () {
              _this.get("notifications").success("The company was successfuly deleted.", { autoClear: true });
            });
          }).catch(function () {
            _this.get("notifications").error("There was an error deleting the company.", { autoClear: true });
          });
        }
      }
    }
  });
});