define('instant-labs/components/charts/relative-abundance', ['exports', 'd3', 'instant-labs/utils/hex-to-rgba', 'chart.js'], function (exports, _d2, _hexToRgba, _chart) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({

    tagName: 'canvas',

    classNames: ["chart"],

    data: Ember.inject.service(),

    chart: null,

    limitGroups: 0,

    didInsertElement: function didInsertElement() {
      this.renderGraph();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.updateGraph();
    },
    getData: function getData() {
      var _this = this;

      return this.groups.then(function (groups) {

        var color = (0, _d2.scaleOrdinal)().range(_d2.schemeCategory20c);

        var samples = _this.samples;

        var labels = samples.map(function (sample) {
          return [sample.get('site.name'), sample.get("sample_location.name"), sample.get("point") + ' - ' + _this.get("data").formatDate(sample.get("collection_date"))];
        });

        // each item in the dataset needs to be a genus
        // with an array that includes values for each sample
        // { label: "Some Genus", data: [3,0,1,2,4], backgroundColor: "#123456" }

        // get dataset
        var groupsAbbrvs = groups.map(function (g) {
          return g.get('abbrv');
        });
        var dataset = {};
        var sample_count = samples.length;
        var sample_totals = new Array(sample_count).fill(0);

        samples.forEach(function (sample, i) {

          sample.get('results').forEach(function (result) {

            var genus = result.get('genus');
            var metabolic_groups = result.get('metabolic_groups');
            var hasGroups = metabolic_groups.some(function (r) {
              return groupsAbbrvs.includes(r);
            });

            if (!+_this.limitGroups || hasGroups) {

              // create empty data array
              if (!(genus in dataset)) {
                dataset[genus] = {
                  data: new Array(sample_count).fill(0),
                  groups: metabolic_groups
                };
              }

              dataset[genus].data[i] = result.get('cell_count');
              sample_totals[i] += result.get('cell_count');
            }
          });
        });

        var datasets = Object.entries(dataset).map(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
              genus = _ref2[0],
              record = _ref2[1];

          var data = record.data.map(function (v, vi) {
            return v / sample_totals[vi];
          });

          return {
            label: genus,
            data: data,
            _groups: record.groups
          };
        });

        datasets = datasets.sort(function (a, b) {
          var aVal = a.data.reduce(function (cur, acc) {
            return acc + cur;
          }, 0);
          var bVal = b.data.reduce(function (cur, acc) {
            return acc + cur;
          }, 0);

          return bVal - aVal;
        });

        // add background color after sort
        datasets = datasets.map(function (datum, i) {
          datum.backgroundColor = (0, _hexToRgba.default)(color(i), "0.7");

          return datum;
        });

        // return
        return {
          labels: labels,
          datasets: datasets
        };
      });
    },
    updateGraph: function updateGraph() {
      var chart = this.chart;

      if (chart) {
        this.getData().then(function (data) {
          chart.data = data;
          chart.update();
        });
      }
    },
    renderGraph: function renderGraph() {
      var _this2 = this;

      var ctx = this.element;

      this.getData().then(function (data) {

        var chart = new _chart.default(ctx, {
          type: "bar",
          data: data,
          options: {
            animation: false,
            aspectRatio: 3,
            legend: {
              display: false
            },
            tooltips: {
              callbacks: {
                label: function label(item, data) {
                  var datum = data.datasets[item.datasetIndex];
                  var label = datum.label;
                  var val = datum.data[item.index] * 100;

                  return label + ': ' + val.toFixed(2) + '%';
                },
                afterLabel: function afterLabel(item, data) {
                  var datum = data.datasets[item.datasetIndex];
                  var groups = datum._groups.join(', ');

                  return 'Groups: ' + groups;
                }
              }
            },
            scales: {
              yAxes: [{
                stacked: true,
                display: false
              }],
              xAxes: [{
                stacked: true,
                gridLines: {
                  display: false
                }
              }]
            }
          }
        });

        _this2.set('chart', chart);
      });
    }
  });
});