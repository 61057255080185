define("instant-labs/routes/admin/samples/edit", ["exports", "instant-labs/mixins/tech-only-mixin"], function (exports, _techOnlyMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_techOnlyMixin.default, {
    titleToken: function titleToken(model) {
      return model ? "Edit " + model.get("collection_date") : "Edit Sample";
    },

    model: function model(params) {
      return this.store.find("sample", params.sample_id);
    }
  });
});