define("instant-labs/models/sample", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // computed
    numericId: Ember.computed("id", function () {
      return Number(this.get("id"));
    }),

    totalProkaryotes: Ember.computed("qpcr_results", function () {
      var qpcr_results = this.get("qpcr_results");

      var total = qpcr_results.find(function (result) {
        var assay = result.get("assay");

        // revisit later to improve matching
        return assay.toLowerCase() === "total prokaryotes";
      });

      return total ? total.get("cell_count") : null;
    }),

    // from API
    other_cell_count: _emberData.default.attr(),
    total_cell_count: _emberData.default.attr(),

    site_id: _emberData.default.attr(),
    sample_location_id: _emberData.default.attr(),

    // new sample information
    number: _emberData.default.attr(),
    label: _emberData.default.attr(),
    point: _emberData.default.attr(),
    collection_date: _emberData.default.attr(),
    type: _emberData.default.attr(),
    test_type: _emberData.default.attr(),
    preserved: _emberData.default.attr(),

    // field chemical testing results
    ph_level: _emberData.default.attr(),
    redox_potential: _emberData.default.attr(),
    temp: _emberData.default.attr(),
    total_dissolved_solids: _emberData.default.attr(),
    sulfide: _emberData.default.attr(),
    nitrate: _emberData.default.attr(),
    dissolved_oxygen: _emberData.default.attr(),
    iron_counts: _emberData.default.attr(),
    chloride: _emberData.default.attr(),
    other_chemicals: _emberData.default.attr(),

    // post creation fields
    description: _emberData.default.attr(),
    ship_date: _emberData.default.attr(),
    arrival_date: _emberData.default.attr(),
    amt_processed: _emberData.default.attr(),
    dna_concentration: _emberData.default.attr(),
    sample_condition: _emberData.default.attr(),
    storing_details: _emberData.default.attr(),
    processed_by: _emberData.default.attr(),
    processed_date: _emberData.default.attr(),
    dna_fwd: _emberData.default.attr(),
    dna_bwd: _emberData.default.attr(),

    // relationships
    site: _emberData.default.belongsTo("site"),
    sample_location: _emberData.default.belongsTo("sample-location"),
    results: _emberData.default.hasMany("result"),
    qpcr_results: _emberData.default.hasMany("qpcr_result"),

    // new record specific attrs
    results_file: _emberData.default.attr("file"),
    qpcr_results_file: _emberData.default.attr("file"),

    // timestamps
    created: _emberData.default.attr(),
    updated: _emberData.default.attr()
  });
});