define("instant-labs/components/charts/metabolic-activities", ["exports", "d3", "instant-labs/utils/hex-to-rgba", "chart.js"], function (exports, _d2, _hexToRgba, _chart) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    tagName: "canvas",

    classNames: ["chart"],

    data: Ember.inject.service(),

    type: 0, // 0 = abundance; 1 = quantity

    test: 0, // 0 = 16S NGS; 1 = qPCR

    chart: null,

    didInsertElement: function didInsertElement() {
      this.renderGraph();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.updateGraph();
    },
    getData: function getData() {
      var _this = this;

      return this.groups.then(function (savedGroups) {
        var self = _this;
        var color = (0, _d2.scaleOrdinal)().range(_d2.schemeCategory10);
        var samples = _this.get("samples");

        var labels = samples.map(function (sample) {
          return [sample.get("site.name"), sample.get("sample_location.name"), sample.get("point") + ' - ' + _this.get("data").formatDate(sample.get("collection_date"))];
        });

        var datasets = [];

        if (_this.test == 0) {
          datasets = savedGroups.map(function (group) {
            var data = samples.map(function (sample) {
              var results = sample.get("results");
              var sample_total = Number(sample.get("total_cell_count"));
              var abbrv = group.get("abbrv");
              var total_prokaryotes = sample.get("totalProkaryotes");

              var val = 0;
              if (self.type == 0 || total_prokaryotes) {
                val = results.filter(function (r) {
                  return r.get("metabolic_groups").includes(abbrv);
                }).reduce(function (acc, cur) {
                  return acc + cur.get("cell_count");
                }, 0);
              }

              // return percentage
              var percentage = sample_total > 0 ? val / sample_total : 0;

              return self.type == 0 ? (percentage * 100).toFixed(1) : Math.round(percentage * total_prokaryotes);
            });

            return {
              label: group.get("description"),
              data: data,
              borderWidth: 1,
              _total: data.reduce(function (a, d) {
                return a + +d;
              }, 0)
            };
          });

          // only show top five groups
          datasets = datasets.sort(function (a, b) {
            return b._total - a._total;
          }).slice(0, 5);

          // add background color after sort
          datasets = datasets.map(function (datum, i) {
            datum.backgroundColor = (0, _hexToRgba.default)(color(i), "0.7");

            return datum;
          });
        } else {
          var dataset = {};
          var sample_count = samples.length;
          var sample_totals = new Array(sample_count).fill(0);

          samples.forEach(function (sample, i) {
            sample.get("qpcr_results").forEach(function (result) {
              var assay = result.get("assay");

              if (!(assay in dataset)) {
                dataset[assay] = {
                  data: new Array(sample_count).fill(0)
                };
              }

              dataset[assay].data[i] = +result.get("cell_count");
              sample_totals[i] += +result.get("cell_count");
            });
          });

          datasets = Object.entries(dataset).map(function (_ref, i) {
            var _ref2 = _slicedToArray(_ref, 2),
                assay = _ref2[0],
                datum = _ref2[1];

            var data = datum.data.map(function (d) {
              return d.toFixed(1);
            });

            return {
              label: assay,
              data: data,
              backgroundColor: (0, _hexToRgba.default)(color(i), "0.7"),
              _total: data.reduce(function (a, d) {
                return a + +d;
              }, 0)
            };
          });
        }

        return {
          labels: labels,
          datasets: datasets
        };
      });
    },
    updateGraph: function updateGraph() {
      var _this2 = this;

      var chart = this.chart;

      if (chart) {
        this.getData().then(function (data) {
          var yAxes = _this2._getYAxis(data);

          chart.options.scales.yAxes = [yAxes];
          chart.data = data;
          chart.update();
        });
      }
    },
    _getYAxis: function _getYAxis(data) {
      var _this3 = this;

      if (this.type == 1 || this.test == 1) {
        var largestY = data.datasets.reduce(function (acc, item) {
          return item._total > acc ? item._total : acc;
        }, 0);

        var prevPowOf10 = 1;

        while (prevPowOf10 * 10 < largestY) {
          prevPowOf10 *= 10;
        }return {
          type: "logarithmic",
          ticks: {
            min: 0,
            max: prevPowOf10 * 10,
            callback: function callback(tickValue, index, ticks) {
              var input = tickValue;

              while (input > 9 && input % 10 == 0) {
                input /= 10;
              }if (input === 1) {
                if (tickValue === 0) {
                  return "0";
                } else {
                  return tickValue.toExponential();
                }
              }

              return null;
            }
          }
        };
      }

      // else
      return {
        type: "linear",
        ticks: {
          min: 0,
          callback: function callback(t) {
            return _this3.type == 0 && _this3.test == 0 ? t + "%" : t;
          }
        }
      };
    },
    renderGraph: function renderGraph() {
      var _this4 = this;

      var ctx = this.element;

      this.getData().then(function (data) {
        var yAxes = _this4._getYAxis(data);

        var chart = new _chart.default(ctx, {
          type: "bar",
          data: data,
          options: {
            animation: false,
            aspectRatio: 3,
            scales: {
              yAxes: [yAxes]
            }
          }
        });

        _this4.set("chart", chart);
      });
    }
  });
});