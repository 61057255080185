define("instant-labs/router", ["exports", "instant-labs/config/environment", "instant-labs/mixins/google-pageview"], function (exports, _environment, _googlePageview) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_googlePageview.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route("auth", function () {
      this.route("login");
      this.route("settings");
      this.route("forgot");
      this.route("logout");
      this.route("reset-password");
    });

    this.route("admin", function () {
      this.route("clients", function () {
        this.route("new");
        this.route("edit", { path: ":client_id/edit" });
      });
      this.route("sites", function () {
        this.route("edit", { path: ":site_id/edit" });
        this.route("new");
      });
      this.route("sample-locations", function () {
        this.route("new");
        this.route("edit", { path: ":sample_location_id/edit" });
      });

      this.route("samples", function () {
        this.route("edit", { path: ":sample_id/edit" });
        this.route("new");
      });
      this.route("users", function () {
        this.route("edit", { path: ":user_id/edit" });
      });
      this.route("classifications", function () {});
    });

    this.route("errors.404", { path: "/*path" });
    this.route("samples", function () {
      this.route("overview");
      this.route("view", { path: ":sample_id" });
    });
    this.route("site-management", function () {
      this.route("site", { path: "site/:site_id" });
    });
  });

  exports.default = Router;
});