define('instant-labs/components/forms/auth/reset-password', ['exports', 'instant-labs/components/common/form-component'], function (exports, _formComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _formComponent.default.extend({

    success: false,

    store: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get("model")) {
        this.set("model", this.get("store").createRecord("password-reset"));
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get("model.isNew")) {
        this.get("model").unloadRecord();
      }
    },


    actions: {
      submitForm: function submitForm() {
        var _this = this;

        var passwordReset = this.get('model');

        passwordReset.save().then(function () {
          _this.set('success', true);
        }).catch(function (errors) {
          errors.description = "Password reset failed";
          _this.handleErrors(errors);
        });
      },
      resetForm: function resetForm() {
        this.set('success', false);
        this.set("model", this.get("store").createRecord("password-reset"));
      }
    }

  });
});