define("instant-labs/routes/site-management/site", ["exports", "instant-labs/mixins/owner-only-mixin"], function (exports, _ownerOnlyMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_ownerOnlyMixin.default, {
    titleToken: function titleToken(model) {
      return model.get("name");
    },

    model: function model(params) {
      return this.store.find("site", params.site_id);
    }
  });
});